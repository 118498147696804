import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import Swal from 'sweetalert2'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const initial = {
  nameForm: 'domaineForm',
  name: '',
  email: '',
  message: '',
  isObnl: false,
  isServices: false,
  isFab: false,
  isFirms: false,
  isContractor: false,
  isOther: false,
}

export default function Domaines() {
  const { t } = useTranslation()
  const [form, setForm] = useState(initial)
  const [loading, setLoading] = useState(false)
  const setField = useCallback(
    (event) => {
      setForm({ ...form, [event.target.name]: event.target.value })
    },
    [form],
  )

  const setCheckField = useCallback(
    (name) => (event) => {
      event.preventDefault()
      setForm({ ...form, [name]: !form[name] })
    },
    [form],
  )

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault()
      setLoading(true)
      axios
        .get(process.env.REACT_APP_MESSAGES, { params: form })
        .then(() => {
          Swal.fire({
            title: t('domaines:successMsg'),
            icon: 'success',
            showCloseButton: true,
            showConfirmButton: false,
            timer: 8000,
          })
          setForm(initial)
        })
        .catch((err) => {
          Swal.fire({
            title: t('domaines:failureMsg'),
            icon: 'error',
            showCloseButton: true,
          })
        })
        .finally(() => setLoading(false))
    },
    [form, t],
  )

  return (
    <section className="domaines_area section_gap" id="domaines">
      <div className="container">
        <div className="text-center mb-4">
          <h2>{t('domaines:title')}</h2>

          <p>{t('domaines:description')}</p>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="container">
              <form
                className="row contact_form"
                id="domaineForm"
                onSubmit={onSubmit}
              >
                <div className="col-md-6">
                  <div className="row">
                    <div
                      className="custom-control item-wrapper col-4 custom-checkbox"
                      onClick={setCheckField('isObnl')}
                    >
                      <div className="mark-div checkbox-container">
                        <div className="">
                          <span className="mr-2 domaines_icon">
                            <i className="fal fa-building"></i>
                          </span>
                        </div>
                        <input
                          type="checkbox"
                          readOnly
                          className="custom-control-input domaines_checklist"
                          id="customCheck5"
                          name="isObnl"
                          // onChange={setCheckField}
                          checked={form.isObnl}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck5"
                        ></label>
                      </div>
                      <p>{t('domaines:obnl')} </p>
                    </div>

                    <div
                      className="custom-control item-wrapper col-4 custom-checkbox domaines_checklist"
                      onClick={setCheckField('isServices')}
                    >
                      <div className="checkbox-container">
                        <div>
                          <span className="mr-2 domaines_icon">
                            <i className="fal fa-phone-laptop"></i>
                          </span>
                        </div>
                        <input
                          type="checkbox"
                          readOnly
                          className="custom-control-input domaines_checklist"
                          id="customCheck6"
                          name="isServices"
                          checked={form.isServices}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck6"
                        ></label>
                      </div>
                      <p>{t('domaines:services')}</p>
                    </div>
                    <div
                      className="custom-control item-wrapper col-4 custom-checkbox "
                      onClick={setCheckField('isFab')}
                    >
                      <div className="checkbox-container">
                        <div>
                          <span className="mr-2 domaines_icon">
                            <i className="fal fa-microchip"></i>
                          </span>
                        </div>
                        <input
                          type="checkbox"
                          readOnly
                          className="custom-control-input domaines_checklist"
                          id="customCheck7"
                          name="isFab"
                          checked={form.isFab}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck7"
                        ></label>
                      </div>
                      <p>{t('domaines:fab')}</p>
                    </div>
                    <div
                      className="custom-control item-wrapper col-4 custom-checkbox domaines_checklist"
                      onClick={setCheckField('isFirms')}
                    >
                      <div className="checkbox-container">
                        <div>
                          <span className="mr-2 domaines_icon">
                            <i className="fal fa-conveyor-belt-alt "></i>
                          </span>
                        </div>
                        <input
                          type="checkbox"
                          readOnly
                          className="custom-control-input domaines_checklist"
                          id="customCheck8"
                          name="isFirms"
                          checked={form.isFirms}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck8"
                        ></label>
                      </div>
                      <p>{t('domaines:firms')}</p>
                    </div>
                    <div
                      className="custom-control item-wrapper col-4 custom-checkbox domaines_checklist"
                      onClick={setCheckField('isContractor')}
                    >
                      <div className="checkbox-container">
                        <div>
                          <span className="mr-2 domaines_icon">
                            <i className="fal fa-industry-alt"></i>
                          </span>
                        </div>
                        <input
                          type="checkbox"
                          readOnly
                          className="custom-control-input domaines_checklist"
                          id="customCheck9"
                          name="isContractor"
                          checked={form.isContractor}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck9"
                        ></label>
                      </div>
                      <p> {t('domaines:contractor')}</p>
                    </div>
                    <div
                      className="custom-control item-wrapper col-4 custom-checkbox domaines_checklist mb-2"
                      onClick={setCheckField('isOther')}
                    >
                      <div className="checkbox-container">
                        <div>
                          <span className="mr-2 domaines_icon">
                            <i className="fal fa-question"></i>
                          </span>
                        </div>
                        <input
                          type="checkbox"
                          readOnly
                          className="custom-control-input domaines_checklist"
                          id="customCheck10"
                          name="isOther"
                          checked={form.isOther}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck10"
                        ></label>
                      </div>
                      <p>{t('domaines:other')}</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="name_domaines_area"
                      name="name"
                      value={form.name}
                      onChange={setField}
                      placeholder={t('domaines:p_name')}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="email_domaines_area"
                      name="email"
                      value={form.email}
                      onChange={setField}
                      placeholder={t('domaines:p_mail')}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="message"
                      id="message_domaines_area"
                      rows="1"
                      value={form.message}
                      onChange={setField}
                      placeholder={t('domaines:p_message')}
                      required
                    ></textarea>
                  </div>
                </div>

                <div className="col-md-12 text-right">
                  <button type="submit" className="custom_btn">
                    {loading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 30, color: 'white' }}
                          />
                        }
                      />
                    ) : (
                      t('domaines:submit')
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
