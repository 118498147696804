import React, { Suspense, lazy } from 'react'
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter,
  useLocation,
} from 'react-router-dom'
import { PuffLoader } from 'react-spinners'
import './translations'
import './App.css'
import Home from './pages/Home'

const Audite = lazy(() => import('./pages/Audite'))
const PME = lazy(() => import('./pages/PME'))
const Consultation = lazy(() => import('./pages/consultation'))
const Amalyet = lazy(() => import('./pages/amalyet'))

function App() {
  const location = useLocation()

  React.useLayoutEffect(() => {
    // window.scroll({ top: 0 })
  }, [location.pathname])

  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/audit" exact component={Audite} />
      <Route path="/pme" exact component={PME} />
      <Route path="/amalyet" exact component={Amalyet} />
      <Route path="/consultation" exact component={Consultation} />
      <Redirect to="/" />
    </Switch>
  )
}

export default ({ children }) => (
  <Suspense
    fallback={
      <div className="row justify-content-center p-5 m-5">
        <PuffLoader color="#6b59f7" />
      </div>
    }
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Suspense>
)
