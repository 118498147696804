export default {
  en: {
    title: `We are accredited`,
    subTitle: `Finance your project`,
    description: `Our consultants are accredited auditors
        by the Quebec Ministry of the Economy and Innovation
        as part of the 4.0 audit and SME productivity programs.`,
    learnMore: `Learn More`,

    items: {
      pme: [
        {
          title: `SME PROGRAM`,
          subTitle: `SME in action Program: Shutter Support for the SME's productivity `,
          description: `This is a Quebec government program offered to manufacturing companies 
          and those from propulsive service industries, associated with financial assistance of up 
          to 40%, for a maximum of $ 50,000. This program "aims to increase the level of 
          productivity of Quebec businesses, in order to enhance competitiveness and 
          accelerate business growth". Here is an opportunity to finally realize your 
          digitization project.`,
        },
      ],
      audit: [
        {
          title: `AUDIT 4.0`,
          subTitle: `Audit industry 4.0: transform your business digital Successfully`,
          description: `It is a Quebec government program offered to manufacturing companies, 
          associated with non-refundable financial assistance of up to $ 15,000. 
          This program "aims to encourage as many Quebec manufacturing companies 
          as possible to go digital, by carrying out a diagnostic and a digital 
          plan related to Industry 4.0". You need to have a strategic plan, 
          and we can help you. Take this opportunity to really know how to carry out 
          your digitization project.`,
        },
      ],
    },
  },

  fr: {
    title: `Nous sommes acrédités`,
    subTitle: `Financer votre projet`,
    description: `Nos consultants sont des auditeurs accrédités 
        par le ministère de l’économie et de l’innovation du québec 
        dans le cadre des programmes de l’audit 4.0 et de la productuvité des PME.`,
    learnMore: `En savoir plus !`,

    items: {
      pme: [
        {
          title: `PROGRAM PME`,
          subTitle: `Programme PME en action : Volet appui à la productivités des PME`,
          description: `C’est un programme du gouvernement du Québec 
                    offert aux entreprises manufacturières et celles du tertiaire moteur,
                    associé avec une aide financière atteignant 40%, pour un maximum de 50.000 $. 
                    Ce programme “vise à accroître le niveau de productivité des entreprises du Québec, 
                    afin de rehausser la compétitivité et d’accélérer la croissance des entreprises”. 
                    Voici une opportunité pour enfin réaliser votre projet de numérisation.`,
        },
      ],
      audit: [
        {
          title: `AUDIT 4.0`,
          subTitle: `Audit industrie 4.0 : Réussir la transformation numérique de votre entreprise`,
          description: ` C’est un programme du gouvernement du Québec offert aux entreprises 
                    manufacturières, associé avec une aide financière non remboursable atteignant 15.000 $.
                    Ce programme “vise à inciter le plus grand nombre d’entreprises manufacturières québécoises 
                    possible à entreprendre le virage numérique, en réalisant un diagnostic 
                    et un plan numérique liés à l’industrie 4.0”. 
                    Vous devez avoir un plan 
                    stratégique, et nous pouvons vous aider. Profitez de cette opportunité pour savoir 
                    réellement comment réaliser votre projet de numérisation.`,
        },
      ],
    },
  },
}
