export default {
  en: {
    title: `About`,
    about: `We are looking for partners`,
    description: [
      `We have built the Amalyet digitalization method, integrated and economical, which aims to turn your digitization project into a reality. Amalyet is based on theoretical foundations so that you take control of your digitization without resorting to costly external consultants for the analysis of your processes. In addition, Amalyet has its own management web portal (software) which can be configured according to your needs and your reality. With Amalyet, you are a double winner.`,
      `Ask us how to become our partner and enjoy privileged treatment in terms of support and price. We take one partner per business line, so hurry and be the first.`,
    ],
  },
  fr: {
    title: `A propos`,
    about: `Nous cherchons des partenaires`,
    description: [
      `Nous avons bâti la méthode de numérisation Amalyet, intégrée et économique, qui a pour objectif de transformer votre projet de numérisation en une réalité. Amalyet est basée sur des fondements théoriques afin que vous preniez le contrôle de votre numérisation sans recourir à des consultants externes coûteux pour l’analyse de vos processus. De plus, Amalyet possède son propre portail web de gestion (logiciel) qui a la possibilité de se configurer suivant vos besoins et votre réalité. Avec Amalyet, vous êtes doublement gagnant.`,
      `Demandez-nous comment devenir notre partenaire et profiter d'un traitement privilégié en termes de support et de prix. Nous prenons un partenaire par secteur d’activités, alors faites vite et soyez le premier.`,
    ],
  },
}
