export default {
  en: {
    methodology: {
      title: `Our digitization methodology`,
      paragraphs: [
        `Our team is accredited by the Quebec Ministry of Economy and Innovation as accredited auditors to pass the Audit 4.0 program. We combine with the material provided by the ministry all our experience to diagnose the state of digital and operational maturity of your organization, with the aim of offering you a web portal specific to you.`,
        `Amalyet is first and foremost a methodology based on theoretical foundations, associated with a systematic diagnostic procedure and supported by an integrated web portal. The method is built on two essential dimensions which are structure and organizational know-how. The structure provides structural content based on the scope of a four-part organization; technical, operational, tactical and strategic. The know-how describes the working methods and procedures of the organization in terms of administration, management and business. Also, each process of the organization is described in terms of these two dimensions.`,
      ],
    },
    approach: {
      title: `Our digitization approach`,
      introduction: `We have worked to optimize our digitization approach so that it complies with the recommendations of the Quebec Ministry of Economy and Innovation. To this end, we propose the following approach:`,
      description: {
        title: `Audit 4.0 + SME in Action Program`,
        introduction: `This is the most interesting way to take full advantage of government grants and our expertise. The Audit 4.0 part focuses on analyzing the state of maturity of your organization with regard to global digitization. The SME in Action step then focuses on the digital implementation of your processes.`,
      },
      audit: {
        title: `Audit 4.0`,
        introduction: `The objective of Audit 4.0 is to carry out a diagnosis of your ability to go digital in your organization. We diagnose your digital maturity by analyzing at least 25 of your processes, 5 of which are at stake. Afterwards, we will establish a digitization plan specific to you. At the end of the mandate, we deliver:`,

        items: [
          `Diagnostic report: Detailed digital diagnostic report of your processes`,
          `Digital maturity calculation: Your digital maturity status for each identified process`,
          `Digital Plan: A plan for the overall digitization of your organization`,
          `Action plan: Intermediate projects for the realization of the digital plan.`,
        ],
        details: `Here are more details on the Audit 4.0 program `,
      },
      pme: {
        title: `SME in action program`,
        introduction: [
          `This part of the program is concerned, among other things, with the implementation in an IT system of your processes, according to the plan previously established in the Audit 4.0 step. The objective of the program remains to increase the productivity of your organization and for this purpose it is interested in the digitization of your value chain (efficiency in the realization of customer projects).`,
          `We offer the implementation of your digitization on the Amalyet web portal which is built to meet the needs of implementation action plans resulting from Audit 4.0. With Amalyet-portal, the implementation approach is as follows:`,
        ],
        items: [
          `Analysis of each process according to the structure and know-how dimensions`,
          `Set up your organization with the processes to be digitized`,
          `Validation of your configuration to calculate the overall efficiency`,
          `Risk-free test of your configuration`,
          `Implementation of your digitization.`,
        ],
        details: `Here are more details on the PME in Action program  `,
      },
    },
    techniques: {
      title: `Amalyet analysis techniques`,
      introduction: `Here is how we will use the Amalyet technique to analyze your organization in order to be able to establish an effective digital implementation plan.`,
    },
    organisation: {
      title: `Efficient organization`,
      image: '/img/consultation/organisation.svg',
      content: `We first identify the constituents of your organization (offers, resources with their activities and costs, budget, partners, etc.) and we group them into three main categories; production, sales and administration, ensuring pre-definition of all your know-how. In Production, we help you create the essential relationships to ensure control and follow-up in the realization of your offers. In sales, we mainly help you increase your ability to bid. Finally, in administration, we help you manage the overall costs of your organization.`,
    },
    operation: {
      title: `Efficient operations`,
      image: '/img/consultation/op_cycle.svg',
      content: `Operations refer to time, and identify your processes. We organize operations as a layer above the efficient organization in order to exploit the pre-configurations of your know-how. We focus on the operations of value, those of production of your offers. Each process is analyzed according to the level of mastery of its execution thanks to our own diagnostic technique. This technique allows, among other things, to define what would be the most efficient method of implementing the execution of a process. The same technique is applied to operational and administrative support processes.`,
    },
    support: {
      title: `Effective tactical supports`,
      image: '/img/consultation/tactic_support.svg',
      content: `To ensure flawless execution of the value chain, an organization must continually provide administrative and operational support. The majority of the supports are also executed in processes, and as much the total control of the latter is great, the support is under control. To this end, it is preferable that the supports are implemented in action plans rather than operational plans. In other words, recruitment or maintenance should not be planned every time, but rather organized as action plans with designed and planned actions, and it will only remain to know when the plan is triggered. Amalyet has what it takes to define and execute action plans.`,
    },
    strategy: {
      title: `Strategic stowage`,
      image: '/img/consultation/strategy.svg',
      content: `Each organization must have strategic directions, from which a strategic plan is determined in due course. The latter includes a set of objectives to be achieved and the organization implements a set of organizational means to achieve them. In Amalyet, we make it possible to create a direct relationship between these resources and support projects (tactical action plans). If all the means are previously defined as support projects, with Amalyet we easily achieve the strategy-tactical-operational linkage, which allows you to really monitor and evaluate your strategy.`,
    },
    scale: {
      title: `Process execution control scale`,
      introduction: `In Amalyet, we have developed a scale representing the different types of execution of operational processes used in the production of offers. This scale reflects the mastery rate of the process as follows:`,
      image: '/img/consultation/steps.svg',
      items: [
        {
          title: `Tasks`,
          description: `The offer is 100% dependent on customer specs, and is performed by tasks defined at the time of project execution`,
        },
        {
          title: `Activities`,
          description: `The offer is 100% dependent on customer specs, however execution is done from predefined resource activities. Activities are taken from job posts`,
        },
        {
          title: `Template`,
          description: `The offer has an activity template for its realization. Customer specs determine the amount of resources to put`,
        },
        {
          title: `Configurator`,
          description: `Customer specs are described in the configurator to generate the activities to be used as well as the quantity of resources`,
        },
        {
          title: `BOM`,
          description: `The offer is made independently of customer specifications. We make our product to sell it to our customers.`,
        },
      ],
      conclusion: `Whatever the level of mastery of your offers, we tailor them with Amalyet.`,
    },
    maturity: {
      title: `Digital maturity scale of a process`,
      image: '/img/consultation/process.svg',
      introduction: `In Amalyet, operational maturity is assessed on business processes using the following scale:`,
      plans: [
        {
          title: `Absent`,
          description: `Process not used in the organisation.`,
        },
        {
          title: `Artisanal`,
          description: `Using the process in a beginner's way, segmented, manual, incomplete, in paper, etc.`,
        },
        {
          title: `Structured`,
          description: `Using the process using structured information in spreadsheets (Excel, etc.)`,
        },
        {
          title: `Integrated`,
          description: `The process is implemented in software.`,
        },
        {
          title: `Efficient`,
          description: `The process communicates with other processes in the same software.`,
        },
        {
          title: `Intelligent`,
          description: `Artificial intelligence is added to the process.`,
        },
      ],
    },
  },
  fr: {
    methodology: {
      title: `Notre méthodologie de numérisation`,
      paragraphs: [
        `Notre équipe est accréditée par le ministère de l’économie et de l’innovation du Québec comme auditeurs accrédités pour passer l’Audit 4.0. Nous associons au matériel fourni par le ministère toute notre expérience pour diagnostiquer l’état de maturité numérique et opérationnelle de votre organisation, avec le but de vous offrir un portail-web spécifique à vous.`,
        `Amalyet est en premier lieu une méthodologie basée sur des fondements théoriques, associée à une procédure de diagnostic systématique est supportée par un portail web intégré. La méthode est construite sur deux dimensions essentielles qui sont la structure et le savoir-faire organisationnel. La structure propose un contenu structurel en fonction de l’envergure d’une organisation en quatre parties; technique, opérationnel, tactique et stratégique. Le savoir-faire quant à lui décrit les méthodes et procédures de travail de l’organisation en termes d’administration, de gestion et de métier. Aussi, chaque processus de l’organisation est décrit en fonction de ces deux dimensions.`,
      ],
    },
    approach: {
      title: `Notre approche de numérisation`,
      introduction: `Nous avons travaillé pour optimiser notre approche de numérisation afin qu’elle soit en conformité avec les recommandations du ministère de l’économie et de l’innovation du Québec. À cet effet, nous proposons l’approche suivante:`,
      description: {
        title: `Audit 4.0 + Programme PME en Action`,
        introduction: `C’est la manière la plus intéressante de profiter pleinement des subventions gouvernementales ainsi que de notre expertise. La partie Audit 4.0 se focalise sur l’analyse de l’état de maturité de votre organisation quant à une numérisation globale. L’étape PME en action s’intéresse alors à l’implémentation numérique de vos processus.`,
      },
      audit: {
        title: `Audit 4.0`,
        introduction: `L’objectif de l’Audit 4.0 est de réaliser un diagnostic de votre capacité à passer à la numérisation de votre organisation. Nous faisons le diagnostic de votre maturité numérique en analysant au moins 25 de vos processus dont 5 à enjeux. Après, nous établirons un plan de numérisation propre à vous. À la fin du mandat, nous vous livrons:`,

        items: [
          `Rapport de diagnostic: Rapport détaillé du diagnostic numérique de vos processus`,
          `Calcul de la maturité numérique: Votre état de maturité numérique pour chaque processus identifié`,
          `Plan numérique: Un plan pour la numérisation globale de votre organisation`,
          `Plan d’action: Les projets intermédiaires pour la réalisation du plan numérique.`,
        ],
        details: `Voici plus de détails sur le programme Audit 4.0`,
      },
      pme: {
        title: `Programme PME en action`,
        introduction: [
          `Cette partie du programme s’intéresse entre autres à l'implémentation dans un système informatique de vos processus, suivant le plan établit auparavant dans l’étape Audit 4.0. L’objectif du programme reste l’augmentation de la productivité de votre organisation et à cet effet il s’intéresse à la numérisation de votre chaîne de valeur (efficacité dans la réalisation des projets clients).`,
          `Nous proposons l’implémentation de votre numérisation sur le portail web Amalyet qui est construit pour répondre aux besoins des plans d’actions d’implémentation issus de l’Audit 4.0. Avec Amalyet-portail, l’approche d’implémentation est la suivante:`,
        ],
        items: [
          `Analyse de chaque processus suivant les dimensions structure et savoir-faire`,
          `Configuration de votre organisation avec les processus à numériser`,
          `Validation de votre configuration pour calculer l’efficacité globale`,
          `Test de votre configuration sans risques`,
          `Implémentation de votre numérisation`,
        ],
        details: `Voici plus de détails sur le programme PME en Action `,
      },
    },
    techniques: {
      title: `Techniques d’analyse de Amalyet`,
      introduction: `Voici dans ce qui suit comment nous allons utiliser la technique Amalyet pour analyser votre organisation afin de pouvoir établir un plan d’implémentation numérique efficace.`,
    },
    organisation: {
      title: `Organisation efficace`,
      image: '/img/consultation/organisation.svg',
      content: `Nous identifions en premier lieu les constituantes de votre organisation (offres, ressources avec leurs activités et coûts, budget, partenaires, etc.) et nous les regroupons en trois grandes catégories; production, ventes et administration, en assurant d’y pré-définir tout votre savoir-faire. Dans Production, nous vous aidons à créer les relations essentielles pour assurer une maîtrise et un suivi dans la réalisation de vos offres. En vente, nous vous aidons principalement à augmenter votre capacité à soumissionner. Enfin, en administration, nous vous aidons à bien gérer les coûts globaux de votre organisation.  `,
    },
    operation: {
      title: `Opérations efficaces`,
      image: '/img/consultation/op_cycle.svg',
      content: `Les opérations font référence au temps, et identifient vos processus. Nous organisons les opérations comme une couche au-dessus de l’organisation efficace afin d’exploiter les pré-configurations de votre savoir-faire. Nous nous concentrons sur les opérations de valeur, celles de production de vos offres. Chaque processus est analysé suivant le niveau de maîtrise de son exécution grâce à notre propre technique de diagnostic. Cette technique permet entre autres de définir quelle serait la méthode la plus efficace d’implémenter l’exécution d’un processus. La même technique est appliquée aux processus de supports opérationnels et administratifs. `,
    },
    support: {
      title: `Supports tactiques efficaces`,
      image: '/img/consultation/tactic_support.svg',
      content: `Pour assurer une exécution sans faille de la chaîne de valeur, une organisation doit lui apporter sans cesse un support administratif et opérationnel. La majorité des supports sont aussi exécutés dans des processus, et autant la maîtrise totale de ces derniers est grande, autant le support est sous contrôle. À cet effet, il est préférable que les supports soient implémentés dans des plans d’actions au lieu que opérationnels. En d’autres termes, un recrutement ou une maintenance ne doivent pas être à chaque fois planifiés, mais plutôt organisés comme des plans d’actions avec des actions conçues et planifiées, et il restera seulement de planifier le moment du déclenchement du plan. Amalyet possède le nécessaire pour définir des plans d’actions et de les exécuter.`,
    },
    strategy: {
      title: `Arrimage stratégique`,
      image: '/img/consultation/strategy.svg',
      content: `Chaque organisation doit posséder des orientations stratégiques, à partir desquelles un plan stratégique est déterminé en temps et lieu. Ce dernier regroupe un ensemble d’objectifs à atteindre et l’organisation met en œuvre un ensemble de moyens organisationnels pour les atteindre. Dans Amalyet, nous permettons de créer une relation directe entre ces moyens et les projets de supports et plans d’actions tactiques. Si tous les moyens sont préalablement définis comme projets de support, nous atteignons facilement avec Amalyet l’arrimage stratégie-tactique-opérationnel, ce qui nous permet de réellement suivre et évaluer une stratégie.`,
    },
    scale: {
      title: `Échelle de maîtrise d’exécution d’un processus`,
      introduction: `Dans Amalyet, nous avons développé une échelle représentant les différents types d’exécution des processus opérationnels utilisés dans la production des offres clients. Cette échelle reflète le taux de maîtrise du processus comme suit:`,
      items: [
        {
          title: `Tâches`,
          description: `L’offre dépend à 100% des specs client, et elle est exécutée par des tâches définies au moment de la réalisation du projet.`,
        },
        {
          title: `Activités`,
          description: `L’offre dépend à 100% des specs client, cependant l’exécution est faite à partir d’activités prédéfinies des ressources. Les activités sont extraites des postes d’emploi.`,
        },
        {
          title: `Template`,
          description: `L’offre possède un template d’activités pour sa réalisation. Les specs clients détermine la quantité des ressources à mettre.`,
        },
        {
          title: `Configurateur`,
          description: `Les specs clients sont décrits dans le configurateur pour générer les activités à utiliser ainsi que la quantité des ressources.`,
        },
        {
          title: `BOM`,
          description: `L’offre est réalisée indépendamment des specs clients. On réalise notre produit pour le vendre à nos clients.`,
        },
      ],
      conclusion: `Quel que soit le niveau de maîtrise de vos offres, nous les réalisons sur mesure avec Amalyet.`,
    },
    maturity: {
      title: `Échelle de maturité numérique d’un processus`,
      image: '/img/consultation/process.svg',
      introduction: `Dans Amalyet, la maturité opérationnelle s’évalue sur les processus métiers en utilisant l’échelle suivante :`,
      plans: [
        {
          title: `Absent`,
          description: `Processus absent.`,
        },
        {
          title: `Artisanal`,
          description: ` Utilisation du processus d’une manière débutante, segmenté, manuel, incomplet, en papier, etc.`,
        },
        {
          title: `Structuré`,
          description: `Utilisation du processus en utilisant de l’information structurée dans des tableurs (Excel, etc.).`,
        },
        {
          title: `Intégré`,
          description: `Le processus est implémenté dans un logiciel.`,
        },
        {
          title: `Efficace`,
          description: `Le processus communique avec d’autres processus dans un même logiciel.`,
        },
        {
          title: `Intelligent`,
          description: `Une intelligence artificielle est ajoutée au processus.`,
        },
      ],
    },
  },
}
