import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguagePicker } from './LanguagePicker'
import './style.css'
import { Link, useLocation } from 'react-router-dom'
import { throttle } from 'throttle-debounce'
import { useEffect } from 'react'

export function Header({ start = 80 }) {
  const { t } = useTranslation()
  const location = useLocation()
  const [isVisible, setIsVisible] = useState(false)
  const [isShadowActive, setIsShadowActive] = useState(window.scrollY > start)
  const [isDropMenuVisible, setDropMenuVisible] = useState(false)

  const onDropMenuVisible = useCallback(() => setDropMenuVisible((v) => !v), [])
  const toggleMenu = useCallback(() => {
    setIsVisible((visible) => !visible)
  }, [])

  const headerHome = location.pathname === '/'
  useEffect(() => {
    const listener = throttle(300, () => {
      setIsShadowActive(window.scrollY > start)
    })
    window.addEventListener('scroll', listener)
    return () => window.removeEventListener('scroll', listener)
  }, [start])

  return (
    <header
      className={isShadowActive ? 'header_area shadow' : 'header_area'}
      style={{
        backgroundColor: headerHome ? '#7170f6' : 'white',
        position: 'sticky',
        top: 0,
        right: 0,
        left: 0,
      }}
    >
      <div className="main_menu">
        <nav
          className="navbar navbar-expand-lg navbar-light "
          style={{ position: 'sticky', top: '0', left: '0', right: '0' }}
        >
          <div className="container ">
            <Link className="navbar-brand logo_h" to="/">
              {!headerHome ? (
                <img src="img/Logo.svg" width="160" alt="" />
              ) : (
                <img src="img/Logo_white.svg" width="160" alt="" />
              )}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleMenu}
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>

            <div
              className={
                'collapse navbar-collapse offset ' + (isVisible ? ' show ' : '')
              }
              style={{ overflow: 'initial' }}
              id="navbarSupportedContent"
            >
              <ul className="nav navbar-nav mr-auto menu_nav justify-content-end nav-items">
                <li className="nav-item ">
                  <a
                    className={
                      'nav-link active' +
                      (!isVisible && !headerHome ? ' HeaderPMEAuditeCss ' : ' ')
                    }
                    href="/#about-us"
                  >
                    {t('header:about')}
                  </a>
                </li>
                <li className="nav-item submenu dropdown">
                  <span
                    className={
                      'nav-link dropdown-toggle' +
                      (!isVisible && !headerHome ? ' HeaderPMEAuditeCss ' : ' ')
                    }
                    style={{ cursor: 'pointer' }}
                    onClick={onDropMenuVisible}
                  >
                    {t('header:services')} &nbsp; &nbsp;
                    <i className="fas fa-sort-down nav-arrow"></i>{' '}
                  </span>
                  <ul
                    className={
                      'dropdown-menu' + (isDropMenuVisible ? ' show ' : ' ')
                    }
                  >
                    <li className="nav-item">
                      <Link
                        className={
                          'nav-link' +
                          (!isVisible && !headerHome
                            ? ' HeaderPMEAuditeCss '
                            : ' ')
                        }
                        to="/consultation"
                      >
                        Consultation
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={
                          'nav-link' +
                          (!isVisible && !headerHome
                            ? ' HeaderPMEAuditeCss '
                            : ' ')
                        }
                        to="/amalyet"
                      >
                        {t('header:amalyet')}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      'nav-link' +
                      (!isVisible && !headerHome ? ' HeaderPMEAuditeCss ' : ' ')
                    }
                    href="/#contact"
                  >
                    {t('header:contact')}
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={
                      'nav-link' +
                      (!isVisible && !headerHome ? ' HeaderPMEAuditeCss ' : ' ')
                    }
                    href="/#consultants"
                  >
                    {t('header:consultants')}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      'nav-link' +
                      (!isVisible && !headerHome ? ' HeaderPMEAuditeCss ' : ' ')
                    }
                    href="/#domaines"
                  >
                    {t('header:domains')}
                  </a>
                </li>

                <li className="nav-item">
                  <LanguagePicker />
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}
