import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import elmehdi from './elmehdi.jpg'
import linkedin from './linkedin.png'

export default function About() {
  const { t } = useTranslation()

  return (
    <section className="conslutans_area section_gap mt-10 " id="consultants">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <div className="main_title mb-0">
              <h2 className="mb-5"> {t('consultans:title')} </h2>
              <img
                src={elmehdi}
                className="rounded-circle mb-5 mt-3"
                alt="Elmehdi Ait Nouri"
              />
              <h3> {t('consultans:name')} </h3>
              <div>
                <a
                  className="d-inline-block rounded-circle "
                  href="https://www.linkedin.com/in/elmehdi-aitnouri"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin} alt="Linkedin profile" />
                </a>
              </div>
              <div className="text-left mt-5">
                <p>
                  <Trans i18nKey="consultans:bio">
                    As a senior consultant at Amalyet, I set myself the
                    objective of providing my clients with all my experience and
                    expertise in the field of management and administration of
                    their organizations, with the aim of a partial or complete
                    digitalization of their processes. I have a technical
                    background with an engineering in communications systems,
                    then a Ph.D. on the use of data in machine intelligence.
                    This technical background allows me to always understand the
                    technical and technological aspects associated with any
                    production of goods and services. Thanks to this expertise,
                    I was able to develop production configurators based on
                    expert systems techniques (example of a configurator of a
                    thermoforming mold production)
                    <a
                      href="https://www.loom.com/share/fd54b21beb714f3ab7e09a74143ccde1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.loom.com/share/fd54b21beb714f3ab7e09a74143ccde1
                    </a>
                    After university studies in project management and then an
                    MBA, I have specialized for more than 15 years in the
                    development of web portals for the management and
                    administration of organizational operations. For this
                    purpose, I have developed the Amalyet method which allows me
                    to analyze the processes well by taking into account the
                    characteristics of the organization in terms of its type of
                    productivity, its scope as well as its need for
                    digitization. The basis of this methodology is implemented
                    in a management and administration web portal that digitizes
                    the organization. With Amalyet, we offer you a one-stop
                    shop. We look forward to sharing Amalyet with you.
                  </Trans>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
