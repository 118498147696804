export default {
  en: {
    title: 'Clients',
    subtitle: `Some of our clients with successful projects.`,
    about: 'About',
    services: 'Services',
    clients: 'Clients',
    domains: 'Domains',
    contact: 'Contact',
    try: 'Try!',
  },
  fr: {
    title: 'Clients',
    subtitle: `Certains de nos clients avec des projets réussis.`,
    about: 'A propos',
    services: 'Services',
    clients: 'Clients',
    domains: 'Domaines',
    contact: 'Contact',
    try: 'Essayer!',
  },
}
