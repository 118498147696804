export default {
  en: {
    title: `Consultants`,
    name: `Elmehdi Aitnouri, Ph.D., MBA`,
    bio: `As a senior consultant at Amalyet, I set myself the objective of providing my clients with all my experience and expertise in the field of management and administration of their organizations, with the aim of a partial or complete digitalization of their processes.
        I have a technical background with an engineering in communications systems, then a Ph.D. on the use of data in machine intelligence. This technical background allows me to always understand the technical and technological aspects associated with any production of goods and services. Thanks to this expertise, I was able to develop production configurators based on expert systems techniques (example of a configurator of a thermoforming mold production) <1>https://www.loom.com/share/fd54b21beb714f3ab7e09a74143ccde1</1>
        After university studies in project management and then an MBA, I have specialized for more than 15 years in the development of web portals for the management and administration of organizational operations. For this purpose, I have developed the Amalyet method which allows a perfect analyzing of any organization processes by taking into account its characteristics in terms of type of productivity, scope as well as the needs for digitization. The basis of this methodology is implemented in a management and administration web portal that digitizes the organization. With Amalyet, we offer you a one-stop shop.
        We look forward to sharing Amalyet with you.`,
  },
  fr: {
    title: `Consultants`,
    name: `Elmehdi Aitnouri, Ph.D., MBA`,
    bio: `En tant que consultant sénior à Amalyet, je me donne l’objectif de faire profiter mes clients de toute mon expérience et expertise dans le domaine de la gestion et l’administration de leurs organisations, dans le but d’une numérisation partielle ou complète de leurs  processus.

        Je possède une formation technique avec un ingéniorat en systèmes de communications, puis un doctorat sur l’utilisation des données en intelligence machine. Ce background technique me permet de toujours comprendre les aspects techniques et technologiques associés à toute production des biens et services. Grâce à cette expertise, j’ai pu développer des configurateurs de production en utilisant entre autres des techniques associant les systèmes experts (example d’un configurateur pour la production des moules de thermoformage) <1>https://www.loom.com/share/fd54b21beb714f3ab7e09a74143ccde1</1>
        Après des études universitaires en gestion de projet puis un MBA, je me spécialise depuis plus de 15 ans dans le développement de portails web de gestion et d’administration des opérations des organisations. J’ai développé à cet effet la méthode Amalyet qui permet de bien analyser les processus d'une organisation en prenant en considération ses caractéristiques en termes de son type de productivité, de son envergure ainsi que son besoin de numérisation. La base de cette méthodologie est implantée dans un portail web de gestion et d’administration qui réalise la numérisation de l’organisation. Avec Amalyet, nous vous offrons un guichet unique.
        Au plaisir de vous faire profiter de Amalyet.`,
  },
}
