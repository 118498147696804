export default {
  en: {
    title: 'You think about digitize your organization, \n become our partner',
    description: {
      introduction: `It doesn't cost you anything to tell us about your projects. If you become our partner, you will get the following benefits:`,
      items: [
        `We help you find all the funding for your digitization project`,
        `We offer you discounts of up to 50% after subsidies for any hour of training and consultation, as well as the cost of Amalyet-Portail`,
        `We help you configure your entire organization`,
        `We configure Amalyet-Portail to meet all your operating needs so that you achieve efficiency`,
      ],
      conclusion: `Contact us to learn more about our partner program. We will take one partner per sector of activity, be the first to benefit from our offer.`,
    },
    name: 'Name',
    mail: 'Email address',
    message: 'Message',
    p_name: ' Enter your name',
    p_mail: 'Enter your email',
    p_message: 'Enter your message',
    submit: 'Submit',
    begin_process: 'Do you think to start your process',
    isProcess: 'Are you in process',
    infoUs: 'Do you want more information about us',
    other: 'Other things...',
    successMsg:
      'Your message has been successfully sent. We will contact you very soon!',
    failureMsg: 'Failed to send your message, try again please!',
  },
  fr: {
    title:
      'Vous pensez numériser votre organisation, \n devenez notre partenaire',
    description: {
      introduction: `Ça ne vous coûte rien de nous en parler de vos projets. Si vous devenez notre partenaire, vous obtiendrez les bénéfices suivants:`,
      items: [
        `Nous vous aidons à chercher tous les financements pour votre projet de numérisation`,
        `Nous vous offrons des rabais allant jusqu’à 50% après les subventions pour toute heure de formation et de consultation, ainsi que le coût  de Amalyet-Portail`,
        `Nous vous aidons à configurer toute votre organisation`,
        `Nous configurons Amalyet-Portail pour répondre à tous vos besoins d’opération afin que vous atteignez l'efficacité`,
      ],
      conclusion: `Contactez-nous pour en savoir plus sur notre programme de partenariat. Nous allons prendre un partenaire par secteur d’activité, soyez le premier à bénéficier de notre offre.`,
    },
    name: 'Nom',
    mail: 'Adresse mail',
    message: 'Message',
    p_name: ' Saisissez votre nom',
    p_mail: 'Saisissez votre adresse mail ',
    p_message: 'Saisissez votre message',
    submit: 'Soumettre',
    begin_process: 'Pensez à entamer le processus',
    isProcess: 'Vous êtes en plein processus',
    infoUs: 'Vous voulez plus d’informations sur nous',
    other: 'Autres...',
    successMsg:
      'Votre message a été envoyé avec succès. Nous vous contacterons très prochainement!',
    failureMsg: `Échec de l'envoi de votre message, veuillez réessayer!`,
  },
}
