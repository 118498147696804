export default {
  en: {
    title: 'Our domains',
    description:
      "It doesn't cost you anything to tell us about your projects. We will give you confidence. We have digitalization expertise in several areas of business, and primarily for small organizations. Ask us!",
    obnl: 'NPO',
    services: 'Services',
    fab: 'Manufacturers',
    firms: 'Engineering firms',
    contractor: 'General contractors',
    other: 'Other...',
    p_name: ' Enter your name',
    p_mail: 'Enter your email',
    p_message: 'Enter your message',
    submit: 'Submit',
    successMsg:
      'Your message has been successfully sent. We will contact you very soon!',
    failureMsg: 'Failed to send your message, try again please!',
  },
  fr: {
    title: 'Nos Domaines',
    description:
      'Ça ne vous coûte rien de nous parler de vos projets, au contraire, nous allons vous mettre en confiance. Nous possédons une expertise en numérisation dans plusieurs domaines d’activités, et principalement pour les petites organisations. Consultez-nous !',
    obnl: 'OBNL',
    services: 'Services',
    fab: 'Fabricants',
    firms: "Firmes d'ingénierie",
    contractor: 'Entrepreneurs généraux',
    other: 'Autres...',
    p_name: ' Saisissez votre nom',
    p_mail: 'Saisissez votre adresse mail ',
    p_message: 'Saisissez votre message',
    submit: 'Soumettre',
    successMsg:
      'Votre message a été envoyé avec succès. Nous vous contacterons très prochainement!',
    failureMsg: `Échec de l'envoi de votre message, veuillez réessayer!`,
  },
}
