/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Header } from '../Header/Header'
import { useTranslation } from 'react-i18next'

export function HomeBanner() {
  const { t } = useTranslation()

  return (
    <>
      <Header start={400} />
      <section className="home_banner_area">
        <div className="banner_inner">
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="banner_content">
                  <h2>
                    {t('home:title')} <br />
                  </h2>
                  <p>{t('home:description')}</p>
                  <div className="d-flex align-items-center">
                    <a className="custom_btn " href="#finance-your-project">
                      {t('home:finance')} <i className="fas fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="home_right_img">
                  <img
                    className="img-fluid"
                    src="img/banner/home-right.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
