export default {
  en: {
    title: `Our comprehensive and integrated digitization approach`,
    approach_1: `Formation`,
    description_1: `Special training on operational efficiency in the context of a global digitization of the organization with Amalyet.`,
    approach_2: `Consultation`,
    description_2: ` A set of private sessions (your team) to help you configure the effectiveness of your organization on Amalyet templates, without any risk.`,
    approach_3: `Amalyet Portal`,
    description_3: `A web portal for management and administration of your operations constituting the digital multi-platform tool implementing your efficiency.`,
    learn_more: `Learn more!`,
  },
  fr: {
    title: `Notre approche de numérisation globale et intégrée`,
    approach_1: `Formation`,
    description_1: `Une formation spéciale sur l’efficacité opérationnelle dans le  contexte d’une numérisation globale de l’organisation avecAmalyet.`,
    approach_2: `Consultation`,
    description_2: ` Un ensemble de séances privées (votre équipe) pour vous aider à configurer l’efficacité de votre organisation sur des templates de Amalyet, sans aucun risque.`,
    approach_3: `Amalyet Portail`,
    description_3: `Un portail web de gestion et d’administration de vos opérations constituant l’outil numérique multi-plateforme implémentant votre efficacité.`,
    learn_more: `En savoir plus !`,
  },
}
