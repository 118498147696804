export default {
  en: {
    title: `Your digitization, it is our business`,
    description: `Your one-stop shop to assist you in carrying out digitization specific to your organization`,
    demo: `Watch the video`,
    begin: `Begin`,
    finance: 'Finance Your Project',
  },
  fr: {
    title: `Votre numérisation, c’est notre affaire`,
    description: `Votre guichet unique pour vous accompagner à réaliser une numérisation propre à votre organisation`,
    demo: `Regarder la vidéo`,
    begin: `Commencer`,
    finance: 'Financer Votre Projet',
  },
}
