import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function ProjectFinanceItem({
  title,
  subTitle,
  description,
  reverse,
  image,
}) {
  const { t } = useTranslation()

  return (
    <div
      className={
        'row features_content ' +
        (reverse ? ' bottom-features flex-row-reverse ' : '')
      }
    >
      <div
        className={reverse ? 'col-lg-5 offset-lg-2' : 'col-lg-4 offset-lg-1'}
      >
        <div className="big_f_left">
          <img className="img-fluid" src={image} alt="" />
        </div>
      </div>
      <div className={reverse ? 'col-lg-5' : 'col-lg-4 offset-lg-2'}>
        <div className="common_style">
          <p className="line">{title}</p>
          <h4>{subTitle}</h4>
          <p>{description}</p>

          <Link to={reverse ? '/pme' : '/audit'} className="custom_btn  mt-3">
            {t('projectFinance:learnMore')}
          </Link>
        </div>
      </div>
      <div className="border-line"></div>
      <img className="shape1" src="img/shape1.svg" alt="" />
      <img className="shape2" src="img/shape2.svg" alt="" />
      <img className="shape3" src="img/shape1.svg" alt="" />
    </div>
  )
}
