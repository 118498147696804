/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function Footer() {
  const { t } = useTranslation()
  return (
    <footer className="footer_area">
      <div className="container">
        <div className="row footer_inner mb-3 justify-content-center text-center ">
          <div className="col-12 col-lg-6">
            <aside className="f_widget ab_widget">
              <div className="f_title mb-2">
                <a className="navbar-brand logo_h" href="index.html">
                  <img src="img/Logo.svg" width="160" alt="" />
                </a>
              </div>
              <p className="mb-2">{t('footer:title')}</p>
              <div className="row justify-content-center">
                {/* <div className="col text-right mr-2">
                  <a
                    href="https://linkedin.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: '#6e67a7',
                    }}
                  >
                    <i className="fab fa-linkedin-in mr-1" />
                    @amalyet
                  </a>
                </div>
                <div className="col mr-3 text-left">
                  <a
                    href="mailto:contact@amalyet.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: '#6e67a7',
                    }}
                  >
                    <i className="fab fa-google mr-1" />
                    contact@amalyet.com
                  </a>
                </div> */}
              </div>
            </aside>
          </div>
        </div>
        <div className="row justify-content-center">
          <p className="text-center">
            Copyright &copy;2018,&nbsp;
            {t('footer:copyright')}{' '}
          </p>
        </div>
      </div>
    </footer>
  )
}
