import React from 'react'
import { HomeBanner } from 'components/HomeBanner/HomeBanner'
import About from 'components/About/About'
import { Approach } from 'components/Approach/Approach'
import ProjectFinance from 'components/ProjectFinance/ProjectFinance'
import Contact from 'components/Contact/Contact'
import Consultans from 'components/Consultans/Consultans'
import Domaines from 'components/Domaines/Domaines'
import Footer from 'components/Footer/Footer'

export default function Home() {
  return (
    <>
      <HomeBanner />
      <About />
      <Approach />
      <ProjectFinance />
      <Contact />

      <Consultans />
      <Domaines />
      <Footer />
    </>
  )
}
