export default {
  en: {
    about: `About`,
    services: `Services`,
    clients: `Clients`,
    consultants: 'Consultants',
    domains: `Domains`,
    contact: `Contact`,
    try: `Try!`,
    amalyet: 'Amalyet Portal',
  },
  fr: {
    about: `A propos`,
    services: `Services`,
    clients: `Clients`,
    consultants: 'Consultants',
    domains: `Domaines`,
    contact: `Contact`,
    try: `Essayer!`,
    amalyet: 'Amalyet Portail',
  },
}
