import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Select } from 'antd'

const { Option } = Select

export function LanguagePicker({ languages }) {
  const { i18n } = useTranslation()

  const changeLanguage = useCallback(
    (lang) => {
      i18n.changeLanguage(lang)
    },
    [i18n],
  )

  const language = i18n.language.slice(0, 2)

  return (
    <div className="dropdown show py-2 py-lg-0">
      <Select
        className="lang-picker"
        value={language}
        onChange={changeLanguage}
        dropdownStyle={{ zIndex: '9999999' }}
      >
        {languages.map(({ code, name }) => (
          <Option value={code} key={code}>
            <img
              className="mr-2"
              style={{ height: '20px' }}
              src={`/img/${code}.png`}
              alt=""
            />
            <span
              style={{
                fontWeight: 'bold',
                display: 'inline-block',
                marginRight: '4px',
              }}
            >
              {name}
            </span>
          </Option>
        ))}
      </Select>
    </div>
  )
}

LanguagePicker.propTypes = {
  languages: PropTypes.array.isRequired,
}

LanguagePicker.defaultProps = {
  languages: [
    { code: 'en', name: 'EN' },
    { code: 'fr', name: 'FR' },
  ],
}
