export default {
  en: {
    home: 'Home',
    subTitleBanner: 'Finance Your Project',
    p_mail: 'Enter your email',
    submit: 'Submit',

    items: {
      audit: [
        {
          titleBanner: 'Audit industry 4.0  ',
          titleContact:
            'Audit industry 4.0: Successful digital transformation of your business',
          description:
            'Open to for-profit manufacturing companies with an establishment in Quebec, as well as cooperatives and social economy companies with commercial activities in the manufacturing sector, it aims to facilitate the transition to digital by carrying out a diagnostic and a digital plan linked to industry 4.0. For more details on this program: ',
          contentA:
            'If you are a manufacturing company, you can benefit from both this diagnostic program and the Productivity Support program for SMEs. The accumulation of direct funding can then reach $ 35,000. Contact us and we will be happy to explain everything to you.',
          contentB:
            'If you are a social economy enterprise, we have something special for you. Communicate with us about NPO programs.',
        },
      ],
      pme: [
        {
          titleBanner: 'SME In Action Program',
          titleContact:
            "SME In Action Program: Support for the productivity's SME",
          description:
            'The Quebec government has set up the SME Program in Action component - Support for the productivity of SMEs. This component aims to increase the productivity level of businesses in Quebec, in order to improve competitiveness and accelerate business growth. This program offers financial support for your digitization project. Financial assistance to the business takes the form of a non-repayable contribution of up to 40% of eligible project expenses, up to a maximum of 50,000$ per project. For more details on this program: ',
          contentA:
            'If you are a manufacturing company, you can benefit from the Audit 4.0 program for diagnosis, and from this program of Support for Productivity of SMEs. The accumulation of direct funding can then reach  35,000 $ Contact us and we will be happy to explain everything to you.',
          contentB:
            'If you are a propulsive service company, you are entitled to this program.',
          subContent: 'Such as information and communications technologies;',
        },
      ],
    },
  },
  fr: {
    home: 'Acceuil',
    subTitleBanner: 'Financer Votre Projet',
    p_mail: 'Saisissez votre adresse mail ',
    submit: 'Soumettre',

    items: {
      audit: [
        {
          titleBanner: 'Audit industrie 4.0  ',
          titleContact:
            'Audit industrie 4.0 : Réussir la transformation numérique de votre entreprise',
          description:
            'Ouvert aux entreprises à but lucratif du secteur manufacturier ayant un établissement au Québec, ainsi qu’aux coopératives et entreprises d’économie sociale ayant des activités marchandes dans le secteur manufacturier, il vise à faciliter le passage au numérique par la réalisation d’un diagnostic et d’un plan numérique lié à l’industrie 4.0. Pour plus de détails sur ce programme : ',
          contentA:
            'Si vous êtes une entreprise manufacturière, vous pouvez bénéficier de ce programme pour le diagnostic, ainsi que du programme de volet Appui à la productivités des PME. L’accumulation du financement direct peut atteindre alors 35.000$. Contactez-nous et il nous fera un grand plaisir de tout vous expliquer.',
          contentB:
            'Si vous êtes une entreprise d’économie sociale, nous avons quelque chose de spéciale pour vous. Communiquez avec nous sur les programmes des OBNL.',
        },
      ],
      pme: [
        {
          titleBanner: 'Programme PME En Action',
          titleContact:
            'Programme PME en action : Volet Appui à la productivités des PME',
          description:
            'Le gouvernement du Québec a mis sur place le Programme PME en action volet - Appui à la productivité des PME. Ce volet vise à accroître le niveau de productivité des entreprises du Québec, afin de rehausser la compétitivité et d’accélérer la croissance des entreprises. Ce programme offre des appuis financiers pour votre projet de numérisation. L’aide financière accordée à l’entreprise prend la forme d’une contribution non remboursable pouvant atteindre 40 % des dépenses admissibles du projet, jusqu’à un maximum de 50 000 $ par projet. Pour plus de détails sur ce programme :',
          contentA:
            'Si vous êtes une entreprise manufacturière, vous pouvez bénéficier du programme d’Audit 4.0 pour le diagnostic, ainsi que de ce programme de volet Appui à la productivités de PME. L’accumulation du financement direct peut atteindre alors 35.000$. Contactez-nous et il nous fera un grand plaisir de tout vous expliquer.',
          contentB:
            'Si vous êtes une entreprise du tertiaire moteur, vous avez droit à ce programme.',
          subContent:
            'les technologies de l’information et des communications;',
        },
      ],
    },
  },
}
