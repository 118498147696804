export default {
  en: {
    contentTable: {
      title: 'Content',
    },
    intro: {
      title: 'Amalyet-portal: Integrated web portal',
      paragraphs: [
        'Amalyet-portal is a web portal for managing and administering the operations of organizations implementing the Amalyet digitization method. It is built to meet the needs of operational efficiency as a goal of digitization. Likewise, it also allows communication with other IT systems to ensure global integration, such as accounting systems.',
        `From a technological point of view, Amalyet-portal is the result of several years of development and deployment of dozens of web portals to different organizations in different business sectors. Amalyet-portal has its own architecture and is technologically very mature.`,
        `From the basic architecture, we are implementing three variants of Amalyet-portal intended for three different industries, providing solutions to certain challenges in these industries.`,
      ],
      construction: {
        title: `Construction`,
        introduction: `General contractors for whom we provide solutions: `,
        items: [
          `Create cotes,very fastly, with the most detailed and fairest budget`,
          `Integrate as many subcontractors as possible in projects`,
          `Create product / service configurators`,
          `Make several variants of budgets for cotes and realizations`,
          `Manage in details customer's changes during realizations`,
          `Manage internal resources (labor and machines)`,
          `Manage construction materials, purchase and use`,
          `Manage several sites at the same time`,
          `Integrated CRM`,
        ],
      },
      job: {
        title: `Job-Shop`,
        introduction: `Manufacturers who are generally subcontractors to major contractors for whom we provide the following solutions:`,
        items: [
          `Increase the ability to bid with prices close to reality`,
          `Automatic cotes using the configuration of manufactured products`,
          `Have a cote budget structure ready to be used in production`,
          `Manage both custom and continuous manufactured products `,
          `Run multiple projects in parallel`,
          `Optimize organizational knowledge`,
          `Manage workshops (operators, machine maintenance, quality, etc.)`,
          `Integrated CRM`,
        ],
      },
      services: {
        title: `Services`,
        introduction: `Companies that offer services, including engineering firms, whose service is heavily dependent on customer specifications, and which have the challenge, among other things, of:`,
        items: [
          `Accuracy of the estimate of the service to be offered without planning the project`,
          `Realization planning`,
          `Run multiple projects in parallel`,
          `Have a global timesheet resource tracking system`,
          `Continuous development of new services`,
          `Integrated CRM`,
        ],
      },
    },
    architecture: {
      title: `Architecture`,
      image: `/img/amalyet/architecture.svg`,
      intro: `Amalyet-portal consists of five services as follows.`,
      sections: [
        {
          title: `Organization configuration`,
          description: `With this service, Amalyet-portal is able to configure any organization, whatever its sector of activity and its size.`,
        },
        {
          title: `Operationalization`,
          description: `This service implements all organizational processes that require planning and monitoring. This is the heart of Amalyet-portal since it implements the production process of customer offers.`,
        },
        {
          title: `Plans`,
          description: `This service ensures the creation and execution of the various plans of the organization, starting with the organizational budget, the departmental tactical plans as well as the strategic plan with its objectives.`,
        },
        {
          title: `A-Suite`,
          description: `An integrated office suite personalized to each user including a document manager, email, communication, notification, etc. Everything that is mandatory for an employee (timesheet, presence, etc.) is included in A-Suite.`,
        },
        {
          title: `Reports`,
          description: `A whole service with personalized reports, a dynamic crosstab report as well as a dynamic reporting system using BI.`,
        },
      ],
    },
    kernel: {
      title: `Organization configuration`,
      id: `amalyet-core`,
      image: `/img/amalyet/kernel.svg`,
      intro: `It represents the technique in terms of structure, and implements all the a priori knowledge about how an organization operates:`,
      sections: [
        {
          title: `Administration configuration`,
          id: `amalyet-core-configuration`,
          description: `It mainly configures the HR and accounting departments, namely employees, job types, costs, budget structure, administration activities, suppliers, etc.`,
        },
        {
          title: `Sales setup`,
          id: `amalyet-core-administration`,
          description: `We configure the offers (products & services), customers, sellers, sales activities, sales conditions, the structure of the quote, etc.`,
        },
        {
          title: `Production setup`,
          id: `amalyet-core-business`,
          description: `We configure how we carry out customer orders with the production process, production resources (humans, machines, materials), the execution of offers (tasks, activities, templates, configurators, BOM), subcontractors, quality , maintenance, etc.`,
        },
      ],
    },
    operational: {
      title: `Operationalization`,
      id: `amalyet-operationalization`,
      image: `/img/amalyet/operational.svg`,
      intro: `This group is the heart of Amalyet-portal, where operational business processes are executed, and includes:`,
      sections: [
        {
          title: `Content management`,
          id: `amalyet-operationalization-content-management`,
          description: `This module can represent the different contents depending on the maturity of processes.`,
        },
        {
          title: `Planning`,
          id: `amalyet-operationalization-planning`,
          description: `It includes planning of content, resources, workload, budgets and times.`,
        },
        {
          title: `Execution`,
          id: `amalyet-operationalization-execution`,
          description: `It is the form of the content that determines how the activities are carried out.`,
        },
        {
          title: `Control and monitoring`,
          id: `amalyet-operationalization-execution-monitoring`,
          description: `With reports comparing budget / actual`,
        },
      ],
    },
    plans: {
      title: `Plans`,
      id: `amalyet-plans`,
      image: `/img/amalyet/plan.svg`,
      intro: `The plans group implements all of the organization's plans in Amalyet-portal.`,
      sections: [
        {
          title: `Budget`,
          id: `amalyet-plans-budget`,
          description: `This is the first plan of an organization. This module allows among other things the connection with an accounting system.`,
        },
        {
          title: `Tactical plans`,
          id: `amalyet-plans-tactical`,
          description: `All departmental annual plans.`,
        },
        {
          title: `Strategic plan`,
          id: `amalyet-plans-strategic`,
          description: `This module makes it possible to align the strategic objectives with their operationalizations via the tactical plans.`,
        },
      ],
    },
    suite: {
      title: 'A-Suite',
      id: 'amalyet-suite',
      image: '/img/amalyet/suite.svg',
      intro:
        'It is an office suite integrated into Amalyet-portal which also implements a lot of administrative processes. The A-Suite service has 4 groups:',
      sections: [
        {
          title: 'Communication',
          id: 'amalyet-suite-communication',
          description:
            'It implements the communication, email and notification management module.',
        },
        {
          title: 'Documents',
          id: 'amalyet-suite-documents',
          description: 'A document manager with templates.',
        },
        {
          title: 'Time',
          id: 'amalyet-suite-time',
          description: 'Calendar, timesheet, attendance.',
        },
        {
          title: 'Profil',
          id: 'amalyet-suite-profile',
          description:
            'User profile with all the personalization settings of his portal, including security, display, notifications, etc.',
        },
      ],
    },
    reports: {
      title: 'Rapports',
      id: 'amalyet-reports',
      image: '/img/amalyet/reports.svg',
      intro:
        'This service is built on a business intelligence type representation of all Amalyet-portal data. This service contains 3 groups of reports:',
      sections: [
        {
          title: 'Static',
          id: 'amalyet-reports-statiques',
          description: 'A set of reports chosen by you.',
        },
        {
          title: 'Dynamic crossover',
          id: 'amalyet-reports-croise-dynamique',
          description: 'A dynamic crosstab report.',
        },
        {
          title: 'Custom',
          id: 'amalyet-reports-dynamic',
          description: 'The ability to create reports as needed.',
        },
      ],
    },
    implementations: {
      title: `Amalyet Implementations`,
      description: `Amalyet Implementations`,
    },
    construction: {
      title: `Amalyet-Construction`,
      id: `amalyet-contruction`,
      introduction: `Here in what follows are more details on the content of Amalyet-construction.`,
      configuration: {
        title: `Organization configuration`,
        id: `amalyet-construction-configuration`,
        introduction: `In the Amalyet-construction core, organizational knowledge is described by its three core processes as follows:`,
        sections: [
          {
            title: `Production`,
            introduction: `We configure: `,
            id: `amalyet-construction-configuration-production`,
            items: [
              `Items: Expenditure items from which we determine the content of each project (electricity, mechanics, plumbing, excavation, etc.) It is a complete hierarchical structure`,
              `Production resources: Human, machinery and materials`,
              {
                title: `Partners and their contacts, associated with their specialties`,
                items: [
                  `Professionals related to types of engineering `,
                  `Subcontractors associated with positions that can be carried out in customer projects `,
                ],
              },
              `Resource activities related to workstations`,
              `Execution of customer projects: Possibility of having several types of execution depending on the mastery of the production process: Configurator, Template, BOO (Bill of Operations), etc.`,
              `Budget and cost structure of each resource associated with positions`,
            ],
          },
          {
            title: `Administration`,
            id: `amalyet-construction-configuration-administration`,
            introduction: `An administration setup is able to support the operations of the organization.`,
            items: [
              `The cost structure of the resources associated with the expenditure items`,
              `The organization's budget`,
              `The employees`,
              `Types of employment`,
              `Administration activities`,
              `Suppliers`,
              `Billing`,
            ],
          },
          {
            title: `Sales`,
            id: `amalyet-construction-configuration-ventes`,
            introduction: `A configuration of the organization's sales procedures.`,
            items: [
              `The structure of the cote and sales email templates`,
              `The taxes`,
              `Sales activities`,
              `Customers`,
              `Sellers`,
              `Reasons for lost cotes`,
            ],
          },
        ],
      },
      operations: {
        title: `Operations`,
        id: `amalyet-construction-operations`,
        introduction: `We implement the operational modules in this service, also grouped together by the sales, construction and administration core processes.`,
        sales: {
          title: `Sales`,
          id: `amalyet-construction-operations-2nd-ventes`,
          introduction: `Operationalization-Sales implements operational processes relating to sales with the following objectives:`,
          items: [
            `Increase the ability to bid`,
            `Have a more precise cost of cotation, and closer to the cost of production`,
            `More or less automatic cotation`,
          ],
          conclusion: `It is a mini CRM with 3 modules.`,
          modules: [
            {
              title: `Opportunities`,
              introduction: `We implement the sub-processes:`,
              items: [
                {
                  title: `Sales targets`,
                  definition: `Planning and monitoring of sales targets for sellers`,
                },
                {
                  title: `Leads`,
                  definition: `To add potential customers to contact`,
                },
                {
                  title: `Opportunities`,
                  definition: `Detailed follow-up of salespeople's activities with potential customers, with the objective of transforming an opportunity into a quote`,
                },
              ],
            },
            {
              title: `Cotes`,
              introduction: `It is possible to pass an opportunity to a cote, or to create a cote directly. The cote module includes 4 sub-processes:`,
              items: [
                {
                  title: `Versions`,
                  definition: `Possibility of establishing different budget scenarios`,
                },
                {
                  title: `Budget`,
                  definition: `Creation of the content of each item of the cotation to calculate the cost in a more or less automatic way`,
                },
                {
                  title: `Invitations`,
                  definition: `Association of subcontractors for the execution of part of the client project, invite them to bid, monitor their cotations and decide with whom to work`,
                },
                {
                  title: `Invoice`,
                  definition: `Envoyer par courriel les soumissions aux clients et faire le suivi.`,
                },
              ],
            },
            {
              title: `Clients`,
              introduction: `This module allows you to follow up on customer accounts:`,
              items: [
                `Integrated communications`,
                `Sales, quotes and opportunities dashboard`,
              ],
            },
          ],
        },
        production: {
          title: `Production`,
          id: `amalyet-construction-operations-2nd-production`,
          introduction: `The Operationalization-Production department implements the essential processes for the construction of customer projects. It contains 2 modules:`,
          modules: [
            {
              title: `Construction`,
              introduction: `This module allows from the budget of a cote to plan and monitor the execution of a client project. It has the sub-processes:`,
              items: [
                {
                  title: `Versions`,
                  definition: `Budget management with the possibility of creating several project budgets`,
                },
                {
                  title: `Work planning`,
                  definition: `Plan in time the course of the activities to be performed in the project (Gantt)`,
                },
                `Management and monitoring of the various amendments (changes)`,
                `Monitoring of the implementation and progress of worksite positions, organizational resources, subcontractors and suppliers`,
                `Subcontractor contracts`,
                `Sub-contractors and suppliers invoicing follow-up`,
                `Reports and reviews`,
              ],
            },
            {
              title: `Purchase`,
              introduction: `This module deals with the various purchases of materials and raw materials from suppliers to be used in all projects in execution.`,
              items: [
                `Purchasing planning for all projects`,
                `Quotes and vouchers`,
                `Reception of materials on site`,
                `Billing follow-up`,
              ],
            },
          ],
        },
        administration: {
          title: `Administration`,
          id: `amalyet-construction-operations-2nd-administration`,
          introduction: `We offer three modules in operationalizing administration. It is possible to add other modules as needed, such as training, etc.`,
          modules: [
            {
              title: `Employees`,
              introduction: `A module allowing you to do any administrative and operational monitoring of your employees.`,
            },
            {
              title: `Recruitment`,
              introduction: `A simple ATS (Applicant Tracking System) that allows you to carry out any recruitment you make.`,
            },
            {
              title: `Projects`,
              introduction: `This module allows you to add projects as needed with the following possibilities:`,
              items: [
                `DDefine the most appropriate way for planning its content as well as its execution (tasks, activities, templates, etc.)`,
                `Choice of resource assignments to put in the project`,
                `Progress and budget monitoring`,
              ],
            },
          ],
        },
        reports: {
          title: `Reports`,
          id: `amalyet-construction-operations-2nd-reports`,
          introduction: `A dynamic reporting system based on business intelligence technicalities, among other things a dynamic cross-reporting system, with the processes:`,
          items: [
            `Generating reports`,
            `Sharing of reports between managers`,
            `Reporting communication system`,
          ],
        },
        suite: {
          title: `A-Suite`,
          id: `amalyet-construction-suites-2nd`,
          introduction: `Amalyet-Suite and an office suite allowing administrative integration into the Amalyet portal and integrating:`,
          items: [
            `A document manager`,
            `A notification manager`,
            `An email manager`,
            `A calendar`,
            `A communication manager`,
            `The timeshee`,
            `tThe presence`,
          ],
        },
      },
    },
    service: {
      title: `Amalyet-Service`,
      id: `amalyet-service`,
      introduction: `Amalyet-Service is a special implementation of Amalyet-portal intended for service organizations, including engineering firms. The objectives of this implementation are among others:`,
      items: [
        `Customer projects: To be able to define the best method of execution of the various customer projects (services) according to their execution maturity (tasks, activities, template, configurator, etc.)`,
        `Manage and administer resources in the multiproject with the possibility of associating different costs`,
        `Cotes: Be able to make very precise quotes in record time`,
        `Detailed monitoring of project executions by resource timesheet`,
        `Amendments: Integration of amendments in the monitoring of project implementation`,
        `Possibility of having a global follow-up of all customer projects at the same time`,
        `Detailed reports`,
      ],
      configuration: {
        title: `Organization configuration`,
        id: `amalyet-configuration`,
        introduction: `In the Amalyet-Service core, knowledge of the organization is described by its three core processes as follows:`,
        sections: [
          {
            title: `Production`,
            id: `amalyet-configuration-production`,
            introduction: `We configure: `,
            items: [
              `Services (Client projects): All client projects with their production processes, budgets and pre-allocation of resources`,
              `Production resources: Employees assignable to client projects with their job types`,
              `Partners and their contacts, professionals who can help in the realization of customer projects`,
              `Resource activities and client project implementation process`,
              `Execution of customer projects: Possibility of having several types of execution depending on the mastery of the production process: Configurator, Template, BOO (Bill of Operations), etc.`,
              `Budget and cost structure of each service`,
            ],
          },
          {
            title: `Administration`,
            id: `amalyet-configuration-administration`,
            introduction: `An administration configuration capable of supporting the operations of the organization.`,
            items: [
              `The cost structure of production resources (simple cost, parameterized, per weekend, etc.)`,
              `The organization's budget`,
              `The employees`,
              `Types of employment`,
              `Administration activities`,
            ],
          },
          {
            title: `Sales`,
            id: `amalyet-configuration-ventes`,
            introduction: `A configuration of the organization's sales procedures`,
            items: [
              `The structure of cotes and sales communication templates (email, etc.) `,
              `Taxes`,
              `Sales activities`,
              `Customers`,
              `Sellers`,
              `Reasons for lost of cotes and opportunities`,
            ],
          },
        ],
      },
      operations: {
        title: `Operations`,
        id: `amalyet-operations`,
        introduction: `We implement in this service the operational modules, also grouped together by the sales, realisation and administration core processes.`,
        sales: {
          title: `Sales`,
          id: `amalyet-operations-ventes`,
          introduction: `Operationalization-Sales implements operational processes relating to sales with the following objectives: `,
          items: [
            `Increase the ability to bid`,
            `Have a more precise cost of cotes, and closer to the cost of realisation`,
            `More or less automatic cotation, in creation and follow-up`,
          ],
          conclusion: `It is a  mini CRM with 3 main modules:`,
          modules: [
            {
              title: `Opportunities`,
              id: `amalyet-operations-ventes-opportunities`,
              introduction: `We implement the sub-processes:`,
              items: [
                {
                  title: `Sales targets`,
                  definition: `Planning and monitoring of sales targets for sellers`,
                },
                {
                  title: `Leads`,
                  definition: `To add potential customers to contact`,
                },
                {
                  title: `Opportunities`,
                  definition: `Detailed follow-up of salespeople's activities with potential customers, with the objective of transforming an opportunity into a quote`,
                },
              ],
            },
            {
              title: `Cotes`,
              id: `amalyet-operations-ventes-soumission`,
              introduction: `It is possible to pass an opportunity to a cote, or to create a cote directly. The cote module includes 2 sub-processes:`,
              items: [
                {
                  title: `Versions`,
                  definition: `Possibility of establishing different budget scenarios`,
                },
                {
                  title: `Invoice`,
                  definition: `Email cotations to clients and following up.`,
                },
              ],
            },
            {
              title: `Clients`,
              id: `amalyet-operations-ventes-clients`,
              introduction: `This module allows you to follow up on customer accounts:`,
              items: [
                `Integrated communications`,
                `Sales, quotes and opportunities dashboard.`,
              ],
            },
          ],
        },
        production: {
          title: `Production`,
          id: `amalyet-operations-ventes-production`,
          introduction: `The Operationalization-Realisation implements the essential processes for the realization of customer projects. It contains 1 module:`,
          modules: [
            {
              title: `Realisation`,
              introduction: `This module allows from the budget of a cote to plan and monitor the execution of a client project. It has the sub-processes:`,
              items: [
                {
                  title: `Client projects`,
                  definition: `The ability to perform all services (customer project). One module for each service`,
                },
                {
                  title: `Versions`,
                  definition: `Budget management with the possibility to create many budgets versions of a project`,
                },
                {
                  title: `Work Planification`,
                  definition: `Plan in time the course of the activities to be performed in the project (Gantt)`,
                },
                `Management and monitoring of the various amendments (changes)`,
                `Monitoring of the implementation and progress of resource activities by timesheet, as well as subcontractors by invoicing`,
                `Contrats des sous-traitants`,
                `Subcontractor management if needed`,
                `Reports`,
              ],
            },
          ],
        },
        administration: {
          title: `Administration`,
          id: `amalyet-operations-ventes-administration`,
          introduction: `We offer three modules in operationalizing administration. It is possible to add other modules as needed, such as training, etc.`,
          modules: [
            {
              title: `Employees`,
              introduction: `A module allowing you to do any administrative and operational monitoring of your employees.`,
            },
            {
              title: `Recruitment`,
              introduction: `A simple ATS (Applicant Tracking System) that allows you to carry out any recruitment you make.`,
            },
            {
              title: `Projects`,
              introduction: `This module allows you to add projects as needed with the following possibilities:`,
              items: [
                `Define the most appropriate way for planning its content as well as its execution (tasks, activities, templates, etc.)`,
                `Choice of resource assignments to put in the project`,
                `Progress and budget monitoring`,
              ],
            },
          ],
        },
        reports: {
          title: `Reports`,
          id: `amalyet-operations-ventes-reports`,
          introduction: `A dynamic reporting system based on business intelligence technicalities, among other things a dynamic cross-reporting system, with the processes:`,
          items: [
            `Generating reports`,
            `Sharing of reports between managers`,
            `Reporting communication system`,
          ],
        },
        suite: {
          title: `A-Suite`,
          id: `amalyet-operations-ventes-suites`,
          introduction: `Amalyet-Suite and an office suite allowing administrative integration into the Amalyet portal and integrating:`,
          items: [
            `A document manager`,
            `A notification manager`,
            `An email manager`,
            `A calendar`,
            `A communication manager`,
            `The timesheet`,
            `The presence`,
          ],
        },
      },
    },
    jobshop: {
      id: `amalyet-jobshop`,
      title: 'Amalyet-JobShop',
      introduction: `Amalyet-JobShop is a special implementation of Amalyet-portal intended for organizations working in the field of manufacturing in general, and specifically custom manufacturing. The major challenges of these companies can be summed up, among other things, by:`,
      items: [
        `Cotes: The prices of client projects are obtained from a quotation which must represent its tailor-made achievement.`,
        `Exact price of bids: Increase the number of bids at the slightest blow and having the most exact prices.`,
        `Custom vs general: Carry out custom projects while making the same products.`,
        `Planning: Build several projects at the same time which makes their planning difficult.`,
        `Learning: The rush of multitudes of client projects means that organizational learning is neglected.`,
        `Computerization: In general, Job-Shops are very small companies that do not have the means to acquire an integrated management system.`,
      ],
      conclusion: `This implementation of Amalyet-Portail aims to meet the efficiency needs of Job-Shop type organizations, at very affordable prices.`,
      configuration: {
        title: 'Organisation configuration',
        id: 'amalyet-jobshop-organisation',
        introduction:
          'In the core of Amalyet-JobShop, organizational knowledge is described by its three core processes as follows:',
        sections: [
          {
            title: 'Manufacturing',
            id: 'amalyet-jobshop-organisation-fabrication',
            introduction:
              'The configuration of knowledge to ensure efficient manufacturing, among others:',
            items: [
              `Workshop layout: It contains the production stations as well as the operations that are carried out in each station`,
              `Production resources: Operators, machines and materials`,
              `Pre-assignment of resources to operations with their cost structures`,
              `Subcontractors who can carry out production operations`,
              `Configuration of manufactured products, either by configurator, BOM, BOO, template, etc.`,
              `Quality: The various quality operations, picking, control points, types of alerts, etc.`,
              `Maintenance: Types of maintenance on machinery`,
            ],
          },
          {
            title: 'Administration',
            id: 'amalyet-jobshop-organisation-administration',
            introduction:
              'An administration configuration capable of supporting the operations of the organization',
            items: [
              `Resource cost structure.`,
              `The organization's budget.`,
              `The employees.`,
              `Types of employment.`,
              `Administration activities.`,
              `Suppliers.`,
            ],
          },
          {
            title: 'Sales',
            id: 'amalyet-jobshop-organisation-ventes',
            introduction:
              "A configuration of the organization's sales procedures",
            items: [
              `The structure of the cote and sales email templates.`,
              `The taxes.`,
              `Sales activities.`,
              `Customers.`,
              `Sellers.`,
              `Reasons for lost cotations.`,
            ],
          },
        ],
        operations: {
          title: `Operations`,
          id: `amalyet-jobshop-operations`,
          introduction: `We implement in this service the operational modules, which are also grouped by the master processes Sales, Production and Administration.`,
          sales: {
            title: 'Sales',
            id: 'amalyet-jobshop-operations-sales',
            introduction: `Operationalization-Sales implements the operational processes relating to sales with the objectives`,
            items: [
              `Increase the ability to bid`,
              `Have a more precise cost of cotation, and closer to the cost of production`,
            ],
            conclusion: `It is a mini CRM with 3 process modules:`,
            modules: [
              {
                title: `Opportunities`,
                introduction: `We implement the sub-processes:`,
                id: `amalyet-jobshop-operations-sales-opportunities`,
                items: [
                  `Sales targets: Planning and monitoring of sales targets for sellers`,
                  `Leads: To add potential customers to contact`,
                  `Opportunities: Detailed follow-up of salespeople's activities with potential customers, with the objective of transforming an opportunity into a quote`,
                ],
              },
              {
                title: `Submission`,
                introduction: `It is possible to pass an opportunity to a cote, or to create a cote directly. The cote includes 5 sub-processes.`,
                id: `amalyet-jobshop-operations-sales-quotes`,
                items: [
                  `Versions: Possibility of establishing different budget scenarios.`,
                  `Budget: Creation of the content of each item of the cote to calculate the cost in a more or less automatic way, using for each product manufactured its configuration.`,
                  `Invitations: Association of subcontractors for the execution of part of the client project, invite them to bid, monitor their cotations and decide with whom to work.`,
                  `Invoice: Email quotes to clients and follow up.`,
                ],
              },
              {
                title: `Clients`,
                introduction: `This module allows you to follow up on customer accounts`,
                id: `amalyet-jobshop-operations-sales-clients`,
                items: [
                  `Integrated communications.`,
                  `Sales, quotes and opportunities dashboard.`,
                ],
              },
            ],
          },
          execution: {
            title: `Execution`,
            id: `amalyet-jobshop-operations-execution`,
            introduction: `The Operationalization-Execution department implements the essential processes for the realization of customer projects (manufacturing). It contains 3 process modules:`,
            modules: [
              {
                id: `amalyet-jobshop-operations-execution-fabrication`,
                title: `Manufacturing`,
                introduction: `This module allows from the budget of a cote to plan and monitor the execution of a client project. It has the sub-processes:`,
                items: [
                  `Budget management with the possibility of creating several project budgets (versions).`,
                  `Work planning: Plan in time the course of the activities to be performed in the project (Gantt).`,
                  `Monitoring of changes made by customers.`,
                  `Manufacturing monitoring by operation.`,
                  `Reports and reviews.`,
                ],
              },
              {
                id: `amalyet-jobshop-operations-execution-maintenance`,
                title: `Maintenance`,
                introduction: `This module allows the management and monitoring of planned preventive and emergency maintenance of workshop machines.`,
                items: [
                  `Action plan for all planned maintenance.`,
                  `Emergency maintenance management: Ability to assign, record (video, files, procedures) of each emergency maintenance.`,
                  `Condition management of each machine requiring maintenance.`,
                ],
              },
              {
                id: `amalyet-jobshop-operations-execution-qualité`,
                title: `Quality`,
                introduction: `This module makes it possible to plan and follow all the quality management procedures on the manufactured products. This module implements the following sub-processes:`,
                items: [
                  `Quality objectives.`,
                  `Picking planning and validation procedures.`,
                  `Operations targeted by quality and control points.`,
                  `Alerts after control`,
                  `Quality reports`,
                ],
              },
            ],
          },
          administration: {
            id: `amalyet-jobshop-operations-administration`,
            title: `Administration`,
            introduction: `We offer three modules in operationalizing administration. It is possible to add other modules as needed, such as training, etc.`,
            modules: [
              {
                title: `Employees`,
                id: 'amalyet-jobshop-operations-administration-employees',
                introduction:
                  'A module allowing you to do any administrative and operational monitoring of your employees.',
              },
              {
                title: `Recruitment`,
                id: 'amalyet-jobshop-operations-administration-recrutement',
                introduction:
                  'A simple ATS (Applicant Tracking System) that allows you to carry out any recruitment you make.',
              },
              {
                title: `Projects`,
                id: 'amalyet-jobshop-operations-administration-projet',
                introduction:
                  'This module allows you to add projects as needed with the following possibilities:',
                items: [
                  `Define the most appropriate way for planning its content as well as its execution (tasks, activities, templates, etc.)`,
                  `Choice of resource assignments to put in the project`,
                  `Progress and budget monitoring`,
                ],
              },
            ],
          },
          reports: {
            id: `amalyet-jobshop-operations-reports`,
            title: `Reports`,
            introduction: `A dynamic reporting system based on business intelligence technicalities, among other things a dynamic cross-reporting system, with the processes:`,
            items: [
              `Generating reports.`,
              `Sharing of reports between managers`,
              `Reporting communication system`,
            ],
          },
          suite: {
            id: `amalyet-jobshop-operations-suite`,
            title: `Amalyet-Suite`,
            introduction:
              'Amalyet-Suite and an office suite allowing administrative integration into the Amalyet portal and integrating',
            items: [
              `A document manager`,
              `A notification manager`,
              `An email manager`,
              `A calendar`,
              `A communication manager`,
              `The timesheet`,
              `The presence`,
            ],
          },
        },
      },
    },
  },
  fr: {
    contentTable: {
      title: 'Contenu',
    },
    intro: {
      title: 'Amalyet-portail: Portail web intégré',
      paragraphs: [
        'Amalyet-portail est un portail web de gestion et d’administration des opérations des organisations implémentant la méthode de numérisation Amalyet. Il est bâti de telle sorte à répondre aux besoins d’efficacité opérationnelle comme objectif de numérisation. De même, il permet aussi de communiquer avec d’autres systèmes informatiques afin d’assurer une intégration globale, comme les systèmes comptables.',
        `D’un point de vue technologique, Amalyet-portail est le résultat de plusieurs années de développement et de déploiement de dizaines de portails web à de différentes organisations dans différents secteurs d’activités. Amalyet-portail possède sa propre architecture et est d’un point de vue technologique très mature.`,
        `À partir de l’architecture de base, nous implémentons trois variantes de Amalyet-portail destinées à trois industries différentes, en apportant des solutions à certains challenges à ces industries.`,
      ],
      construction: {
        title: `Construction`,
        introduction: `Les entrepreneurs généraux pour qui nous apportons les solutions: `,
        items: [
          `Soumissionner avec le budget le plus détaillé et le plus juste en un temps record`,
          `Intégrer le maximum de sous-traitants dans les projets, soumissions et réalisations`,
          `Créer des configurateurs de produits/services `,
          `Faire plusieurs variantes de budgets de soumissions et de réalisations`,
          `Suivre au détail les changements du client dans la réalisation`,
          `Gérer les ressources internes (main d’oeuvres et machines)`,
          `Gérer les matériaux de construction, à l’achat et à la réalisation`,
          `Gérer plusieurs chantiers à la fois`,
          `Un mini CRM intégré`,
        ],
      },
      job: {
        title: `Job-Shop`,
        introduction: `Les fabricants qui sont en général des sous-traitants des grands donneurs d’ordres pour qui nous apportons les solutions suivantes: `,
        items: [
          `Augmenter la capacité à soumissionner avec des prix proches de la réalité`,
          `Automatiser la soumission en utilisant la configuration des produits fabriqués`,
          `Avoir un budget de soumission prêt à être utilisé en production`,
          `Pouvoir gérer la fabrication du custom et du continu`,
          `Exécuter plusieurs projets en parallèle`,
          `Optimiser la connaissance organisationnelle`,
          `Gérer les ateliers (opérateurs, maintenance machine, qualité, etc.)`,
          `Un mini CRM intégré`,
        ],
      },
      services: {
        title: `Services`,
        introduction: `Les entreprises qui offrent un service, incluant les firmes d’ingénieries, dont le service est lourdement dépendant des spécifications clients, et qui ont le challenge entre autres de: `,
        items: [
          `Exactitude de l’estimation du service à offrir sans faire la planification du projet`,
          `Planification de réalisation`,
          `Exécuter plusieurs projets en parallèle`,
          `Avoir un système de suivi global des ressources par timesheet`,
          `Développement continuel de nouveaux services`,
          `Un mini CRM intégré`,
        ],
      },
    },
    architecture: {
      title: `Architecture`,
      id: `amalyet-architecture`,
      image: `/img/amalyet/architecture.svg`,
      intro: `Amalyet-portail se compose de cinq services comme suit :`,
      sections: [
        {
          title: `Configuration de l’organisation`,
          id: `amalyet-architecture-portal-core`,
          description: `Avec ce service, Amalyet-portail est capable de configurer n’importe quelle organisation, quel que soit son secteur d’activité et son envergure.`,
        },
        {
          title: `Opérationnalisation`,
          id: `amalyet-architecture-operationalization-group`,
          description: `Ce service implémente tous les processus organisationnels qui nécessitent une planification et suivi d’exécution. C’est le cœur de Amalyet-portail puisque il implémente le processus de production des offres clients.`,
        },
        {
          title: `Plans`,
          id: `amalyet-architecture-plans-group`,
          description: `Ce service assure la création et l’exécution des différents plans de l’organisation, en commençant par le budget organisationnel, les plans tactiques départementaux ainsi que le plan stratégique avec ses objectifs.`,
        },
        {
          title: `A-Suite`,
          id: `amalyet-architecture-plans-group`,
          description: `Une suite bureautique intégrée personnalisée à chaque utilisateur incluant un gestionnaire de document, courriel, communication, notification, etc. On y inclut dans A-Suite tout ce qui est obligatoire pour un employé (timesheet, présence, etc.)`,
        },
        {
          title: `Rapports`,
          id: `amalyet-architecture-plans-group`,
          description: `Tout un service avec des rapports personnalisés, un rapport croisé dynamique ainsi qu’un système de création de rapports dynamiques utilisant le BI.`,
        },
      ],
    },
    kernel: {
      title: `Configuration de l’organisation`,
      id: `amalyet-core`,
      image: `/img/amalyet/kernel.svg`,
      intro: `Ça représente la technique en termes de structure, et implémente toutes les connaissances à priori sur la manière comment une organisation opère.`,
      sections: [
        {
          title: `Configuration d’administration`,
          id: `amalyet-core-configuration`,
          description: `On y configure principalement les départements RH et comptabilité, à savoir les employés, les types d’emploi, les coûts, la structure budgétaire, les activités d’administration, les fournisseurs, etc.`,
        },
        {
          title: `Configuration des ventes`,
          id: `amalyet-core-administration`,
          description: `On y configure les offres (produits & services), les clients, les vendeurs, les activités de ventes, les conditions de ventes, la structure de la soumission, etc.`,
        },
        {
          title: `Configuration de production`,
          id: `amalyet-core-business`,
          description: `On y configure comment on réalise les commandes clients avec le processus de production, les ressources de production (humains, machines, matériaux), les exécutions des offres (tâches, activités, templates, configurateurs, BOM), les sous-traitants, la qualité, la maintenance, etc.`,
        },
      ],
    },
    operational: {
      title: `Operationalization`,
      id: `amalyet-operationalization`,
      image: `/img/amalyet/operational.svg`,
      intro: `Ce service est le cœur de Amalyet-portail, ou les processus opérationnels sont exécutés, principalement les processus de réalisation des offres clients. Il implémente les différentes manières d’exécutions en relation avec leurs taux de maîtrise. Chaque processus est exécuté dans un module opérationnel qui inclut:`,
      sections: [
        {
          title: `Gestion du contenu`,
          id: `amalyet-operationalization-content-management`,
          description: `Les activités ou opérations que les ressources doivent exécuter. La présentation du contenu dépend de la maturité du processus.`,
        },
        {
          title: `Planification`,
          id: `amalyet-operationalization-planning`,
          description: `Elle inclut au besoin le contenu, les ressources, la charge, les budgets et les temps.`,
        },
        {
          title: `Exécution`,
          id: `amalyet-operationalization-execution`,
          description: `C’est la forme du contenu qui détermine la manière dont les activités sont exécutées.`,
        },
        {
          title: `Contrôle et suivi`,
          id: `amalyet-operationalization-execution-monitoring`,
          description: `Avec des rapports comparant budget/réel.timesheet, etc.`,
        },
      ],
    },
    plans: {
      title: `Plans`,
      id: `amalyet-plans`,
      image: `/img/amalyet/plan.svg`,
      intro: `Le service plans implémente dans Amalyet-portail tous les plans de l’organisation.`,
      sections: [
        {
          title: `Budget`,
          id: `amalyet-plans-budget`,
          description: `C'est le premier plan d’une organisation. Il permet entre autres la connexion avec un système comptable externe.`,
        },
        {
          title: `Plans tactiques`,
          id: `amalyet-plans-tactical`,
          description: `Tous les plans tactiques annuels des départements.`,
        },
        {
          title: `Strategic plan`,
          id: `amalyet-plans-strategic`,
          description: `Ce module permet d’arrimer les objectifs stratégiques avec leurs opérationnalisations via les plans tactiques.`,
        },
      ],
    },
    suite: {
      title: 'A-Suite',
      id: 'amalyet-suite',
      image: '/img/amalyet/suite.svg',
      intro:
        'C’est une suite bureautique intégrée à Amalyet-portail qui implémente aussi beaucoup de processus administratifs. Le service A-Suite possède 4 groupes:',
      sections: [
        {
          title: 'Communication',
          id: 'amalyet-suite-communication',
          description:
            'Ça implémente le module de gestion de communication, de courriel et de notification.',
        },
        {
          title: 'Documents',
          id: 'amalyet-suite-documents',
          description: 'Un gestionnaire de documents avec les templates.',
        },
        {
          title: 'Time',
          id: 'amalyet-suite-time',
          description: 'Calendrier, timesheet, présence.',
        },
        {
          title: 'Profil',
          id: 'amalyet-suite-profile',
          description:
            'Profil de l’utilisateur avec tous les paramètres de personnalisation de son portail, entre autres la sécurité, l’affichage, les notifications, etc.',
        },
      ],
    },
    reports: {
      title: 'Rapports',
      id: 'amalyet-reports',
      image: '/img/amalyet/reports.svg',
      intro:
        'Ce service est construit sur une représentation de type intelligence d’affaire de toutes les données de Amalyet-portail. Ce service contient 3 groupes de rapports:',
      sections: [
        {
          title: 'Statiques',
          id: 'amalyet-reports-statiques',
          description: 'Un ensemble de rapports choisis par vous.',
        },
        {
          title: 'Croisé dynamique',
          id: 'amalyet-reports-croise-dynamique',
          description: 'Un rapport croisé dynamique.',
        },
        {
          title: 'Dynamique',
          id: 'amalyet-reports-dynamic',
          description: 'La possibilité de créer des rapports au besoin.',
        },
      ],
    },
    implementations: {
      title: `Amalyet Implementations`,
      description: `Amalyet Implementations`,
    },
    construction: {
      title: `Amalyet-Construction`,
      id: `amalyet-contruction`,
      introduction: `Voici dans ce qui suit plus de détails sur le contenu de Amalyet-construction.`,
      configuration: {
        title: `Configuration de l’organisation`,
        id: `amalyet-construction-configuration`,
        introduction: `Dans le noyau Amalyet-construction, la connaissance de l’organisation est décrite par ses trois processus noyaux comme suit:`,
        sections: [
          {
            title: `Production`,
            introduction: `On y configure: `,
            id: `amalyet-construction-configuration-production`,
            items: [
              `Postes: Postes de dépenses à partir desquels on détermine le contenu de chaque projet (électricité, mécanique, plomberie, excavation, etc.) C’est une structure hiérarchique complète`,
              `Ressources de production: Humaines, machineries et matériaux`,
              {
                title: `Partenaires et leurs contacts, associés à leurs spécialités`,
                items: [
                  `Professionnels en relation avec les types d’ingénieries `,
                  `Les sous-traitants associés aux postes pouvant être réalisés dans des projets clients `,
                ],
              },
              `Activités des ressources en relation avec les postes de travail`,
              `Exécution des projets clients: Possibilité d’avoir plusieurs types d’exécutions en fonction de la maîtrise du processus de production: Configurateur, Template, BOO (Bill of Operations), etc.`,
              `Budget et structure des coûts de chaque ressource associée aux postes`,
            ],
          },
          {
            title: `Administration`,
            id: `amalyet-construction-configuration-administration`,
            introduction: `Une configuration d’administration capable de supporter les opérations de l’organisation.`,
            items: [
              `La structure des coûts des ressources associées aux postes de dépenses`,
              `Le budget de l’organisation`,
              `Les employés`,
              `Les types d’emploi`,
              `Les activités d’administration`,
              `Les fournisseurs`,
            ],
          },
          {
            title: `Ventes`,
            id: `amalyet-construction-configuration-ventes`,
            introduction: `Une configuration des procédures de ventes de l’organisation.`,
            items: [
              `La structure de la soumission et templates de courriels de vente`,
              `Les taxes`,
              `Les activités de ventes`,
              `Les clients`,
              `Les vendeurs`,
              `Les raisons de perte des soumissions`,
            ],
          },
        ],
      },
      operations: {
        title: `Opérations`,
        id: `amalyet-construction-operations`,
        introduction: `Nous implémentons dans ce service les modules opérationnels, regroupés aussi par les processus noyaux Ventes, construction et Administration.`,
        sales: {
          title: `Ventes`,
          id: `amalyet-construction-operations-2nd-ventes`,
          introduction: `L’opérationnalisation-Ventes implémente les processus opérationnels relatifs aux ventes avec les objectifs suivants: `,
          items: [
            `Augmenter la capacité de soumissionner`,
            `Avoir un coût de soumission plus précis, et plus proche du coût de production`,
            `Soumission plus ou moins automatique`,
          ],
          conclusion: `C’est un mini CRM possédant 3 modules:`,
          modules: [
            {
              title: `Opportunités`,
              introduction: `On y implémente les sous-processus:`,
              items: [
                {
                  title: `Objectifs de ventes`,
                  definition: `Planification et suivi des objectifs de ventes des vendeurs`,
                },
                {
                  title: `Leads`,
                  definition: `Pour ajouter les potentiels clients à contacter`,
                },
                {
                  title: `Opportunités`,
                  definition: `Suivi au détail des activités des vendeurs avec les éventuels clients, avec l’objectif de transformer une opportunité en soumission`,
                },
              ],
            },
            {
              title: `Soumission`,
              introduction: `Il est possible de faire passer une opportunité à une soumission, ou de créer directement une soumission. La soumission inclut 4 sous-processus:`,
              items: [
                {
                  title: `Versions`,
                  definition: `Possibilité d’établir différents scénarios de budget`,
                },
                {
                  title: `Budget`,
                  definition: `Création du contenu de chaque item de la soumission pour en calculer le coût d’une manière plus ou moins automatique`,
                },
                {
                  title: `Invitations`,
                  definition: `Association de sous-traitants pour l’exécution d’une partie du projet client, les inviter à soumissionner, suivre leurs soumissions et décider avec qui travailler`,
                },
                {
                  title: `Invoice`,
                  definition: `Envoyer par courriel les soumissions aux clients et faire le suivi.`,
                },
              ],
            },
            {
              title: `Clients`,
              introduction: `Ce module permet de faire un suivi des comptes clients:`,
              items: [
                `Communications intégrées`,
                `Tableau de bord des ventes, soumissions et opportunités`,
              ],
            },
          ],
        },
        production: {
          title: `Production`,
          id: `amalyet-construction-operations-2nd-production`,
          introduction: `Le service Opérationnalisation-Production implémente les processus essentiels pour la construction des projets clients. Il contient 2 modules:`,
          modules: [
            {
              title: `Construction`,
              introduction: `Ce module permet à partir du budget d’une soumission de planifier et de suivre l’exécution d’un projet client. Il possède les sous-processus:`,
              items: [
                {
                  title: `Versions`,
                  definition: `Gestion du budget avec la possibilité de créer plusieurs budgets du projet`,
                },
                {
                  title: `Planification des travaux`,
                  definition: `Planifier dans le temps le déroulement des activités à être exécutées dans le projet (Gantt)`,
                },
                `Gestion et suivi des différents avenants (changements)`,
                `Suivi de réalisation et avancements des postes sur chantier, ressources de l’organisation, sous-traitants et fournisseurs`,
                `Contrats des sous-traitants`,
                `Suivi de facturation des sous-traitants et fournisseurs`,
                `Rapports et bilans`,
              ],
            },
            {
              title: `Achat`,
              introduction: `Ce module traite des différents achats de matériaux et matières premières des fournisseurs à utiliser dans tous les projets en exécution.`,
              items: [
                `Planification des achats pour tous les projets`,
                `Soumissions et bons d’achats`,
                `Réception des matériaux sur chantier`,
                `Suivi de facturation`,
              ],
            },
          ],
          production: {
            title: `Production`,
            introduction: `Le service Opérationnalisation-Production implémente les processus essentiels pour la construction des projets clients. Il contient 2 modules:`,
            modules: [
              {
                title: `Construction`,
                introduction: `Ce module permet à partir du budget d’une soumission de planifier et de suivre l’exécution d’un projet client. Il possède les sous-processus:`,
                items: [
                  {
                    title: `Versions`,
                    definition: `Gestion du budget avec la possibilité de créer plusieurs budgets du projet`,
                  },
                  {
                    title: `Planification des travaux`,
                    definition: `Planifier dans le temps le déroulement des activités à être exécutées dans le projet (Gantt)`,
                  },
                  `Gestion et suivi des différents avenants (changements)`,
                  `Suivi de réalisation et avancements des postes sur chantier, ressources de l’organisation, sous-traitants et fournisseurs`,
                  `Contrats des sous-traitants`,
                  `Suivi de facturation des sous-traitants et fournisseurs`,
                  `Rapports et bilans`,
                ],
              },
              {
                title: `Achat`,
                introduction: `Ce module traite des différents achats de matériaux et matières premières des fournisseurs à utiliser dans tous les projets en exécution.`,
                items: [
                  `Planification des achats pour tous les projets`,
                  `Soumissions et bons d’achats`,
                  `Réception des matériaux sur chantier`,
                  `Suivi de facturation`,
                ],
              },
            ],
          },
        },
        administration: {
          title: `Administration`,
          id: `amalyet-construction-operations-2nd-administration`,
          introduction: `Nous offrons trois modules dans l'opérationnalisation de l’administration. Il est possible d’ajouter d’autres modules au besoin, comme la formation, etc:`,
          modules: [
            {
              title: `Employés`,
              introduction: `Un module permettant de faire n’importe quel suivi administratif et opérationnel de vos employés.`,
            },
            {
              title: `Recrutement`,
              introduction: `Un ATS (Applicant Tracking System) simple qui vous permet d’opérer les éventuels recrutement que vous faites.`,
            },
            {
              title: `Projets`,
              introduction: `Ce module permet d’ajouter des projets au besoin avec les possibilités suivantes:`,
              items: [
                `Définir la manière la plus appropriée pour la planification de son contenu ainsi que son exécution (tâches, activités, templates, etc.)`,
                `Choix des assignations des ressources à mettre dans le projet`,
                `Suivi d’avancement et du budget`,
              ],
            },
          ],
        },
        reports: {
          title: `Rapports`,
          id: `amalyet-construction-operations-2nd-reports`,
          introduction: `Un système de rapports dynamiques à base de technicités d’intelligence d’affaire, entre autre un système de rapport croisés dynamiques, avec les processus:`,
          items: [
            `Génération des rapports`,
            `Partage des rapports entre gestionnaires`,
            `Système de communication sur les rapports`,
          ],
        },
        suite: {
          title: `A-Suite`,
          id: `amalyet-construction-suites-2nd`,
          introduction: `Amalyet-Suite et une suite office permettant une intégration administrative au portail Amalyet et intégrant:`,
          items: [
            `Un gestionnaire de documents`,
            `Un gestionnaire de notifications`,
            `Un gestionnaire de courriels`,
            `Un calendrier`,
            `Un gestionnaire de communication`,
            `Le timesheet`,
            `La présence`,
          ],
        },
      },
    },
    service: {
      title: `Amalyet-Service`,
      id: `amalyet-service`,
      introduction: `Amalyet-Service est une implémentation spéciale de Amalyet-portail destinée aux organisations de services, incluant les firmes d’ingénieries. Les objectifs de cette implémentation sont entre autres:`,
      items: [
        `Projets clients: Pouvoir définir la meilleure méthode d’exécution des différents projets clients (services) en fonction de leur maturité d’exécution (tâches, activités, template, configurateur, etc.)`,
        `Gérer et administrer les ressources dans le multiprojet avec la possibilité d’y associer différents coûts`,
        `Soumission: Pouvoir faire des soumissions très précises en un temps record`,
        `Suivi détaillé des exécutions de projet par timesheet des ressources`,
        `Avenants: Intégration des avenants dans le suivi de réalisation des projets`,
        `Possibilité d’avoir un suivi global de tous les projets clients en même temps`,
        `Rapports détaillés`,
      ],
      configuration: {
        title: `Configuration de l’organisation`,
        id: `amalyet-configuration`,
        introduction: `Dans le noyau Amalyet-Service, la connaissance de l’organisation est décrite par ses trois processus noyaux comme suit:`,
        sections: [
          {
            title: `Production`,
            introduction: `On y configure: `,
            id: `amalyet-configuration-production`,
            items: [
              `Projets clients: Tous les projets clients avec leurs processus de réalisation, leurs budgets et pré-assignation des ressources`,
              `Ressources de production: Les employés assignables aux projets clients avec leurs types d’emploi`,
              `Partenaires et leurs contacts, professionnels qui peuvent aider dans la réalisation des projets clients`,
              `Activités des ressources et processus de réalisation des projets clients`,
              `Exécution des projets clients: Possibilité d’avoir plusieurs types d’exécutions en fonction de la maîtrise du processus de production: Configurateur, Template, BOO (Bill of Operations), etc.`,
              `Budget et structure des coûts de chaque ressource de production`,
            ],
          },
          {
            title: `Administration`,
            id: `amalyet-configuration-administration`,
            introduction: `Une configuration d’administration capable de supporter les opérations de l’organisation.`,
            items: [
              `La structure des coûts des ressources de production (coût simple, paramétré, par fin de semaine, etc.)`,
              `Le budget de l’organisation`,
              `Les employés`,
              `Les types d’emploi`,
              `Les activités d’administration`,
            ],
          },
          {
            title: `Ventes`,
            id: `amalyet-configuration-ventes`,
            introduction: `Une configuration des procédures de ventes de l’organisation`,
            items: [
              `La structure de la soumission et templates de courriels de vente`,
              `Les taxes`,
              `Les activités de ventes`,
              `Les clients`,
              `Les vendeurs`,
              `Les raisons de perte des soumissions`,
            ],
          },
        ],
      },
      operations: {
        title: `Opérations`,
        id: `amalyet-operations`,
        introduction: `Nous implémentons dans ce service les modules opérationnels, regroupés aussi par les processus noyaux Ventes, Réalisation et Administration.`,
        sales: {
          title: `Ventes`,
          id: `amalyet-operations-ventes`,
          introduction: `L’opérationnalisation-Ventes implémente les processus opérationnels relatifs aux ventes avec les objectifs suivants: `,
          items: [
            `Augmenter la capacité de soumissionner`,
            `Avoir un coût de soumission plus précis, et plus proche du coût de production`,
            `Soumission plus ou moins automatique, durant la création et le suivi`,
          ],
          conclusion: `C’est un genre de mini CRM possédant 3 modules:`,
          modules: [
            {
              title: `Opportunités`,
              id: `amalyet-operations-ventes-opportunities`,
              introduction: `On y implémente les sous-processus:`,
              items: [
                {
                  title: `Objectifs de ventes`,
                  definition: `Planification et suivi des objectifs de ventes des vendeurs`,
                },
                {
                  title: `Leads`,
                  definition: `Pour ajouter les potentiels clients à contacter et faire des analyses`,
                },
                {
                  title: `Opportunités`,
                  definition: `Suivi au détail des activités des vendeurs avec les éventuels clients, avec l’objectif de transformer une opportunité en soumission`,
                },
              ],
            },
            {
              title: `Soumission`,
              id: `amalyet-operations-ventes-soumission`,
              introduction: `Il est possible de faire passer une opportunité à une soumission, ou de créer directement une soumission. La soumission inclut 2 sous-processus:`,
              items: [
                {
                  title: `Versions`,
                  definition: `Possibilité d’établir différents scénarios de budget`,
                },
                {
                  title: `Invoice`,
                  definition: `Envoyer par courriel les soumissions aux clients et faire le suivi.`,
                },
              ],
            },
            {
              title: `Clients`,
              id: `amalyet-operations-ventes-clients`,
              introduction: `Ce module permet de faire un suivi des comptes clients:`,
              items: [
                `Communications intégrées`,
                `Tableau de bord des ventes, soumissions et opportunités`,
              ],
            },
          ],
        },
        production: {
          title: `Réalisation`,
          id: `amalyet-operations-ventes-production`,
          introduction: `Le service Opérationnalisation-Réalisation implémente les processus essentiels pour la réalisation des projets clients. Il contient 1 module:`,
          modules: [
            {
              title: `Réalisation`,
              introduction: `Ce module permet à partir du budget d’une soumission de planifier et de suivre l’exécution d’un projet client. Il possède les sous-processus:`,
              items: [
                {
                  title: `Projet clients`,
                  definition: `Possibilité d’exécuter tous les services (projet clients). Un module par service`,
                },
                {
                  title: `Versions`,
                  definition: `Gestion du budget avec la possibilité de créer plusieurs budgets du projet`,
                },
                {
                  title: `Planification des travaux`,
                  definition: `Planifier dans le temps le déroulement des activités à être exécutées dans le projet (Gantt)`,
                },
                `Gestion et suivi des différents avenants (changements)`,
                `Suivi de réalisation et avancements des activités des ressources par timesheet, ainsi que les sous-traitants par facturation`,
                `Gestion des sous-traitants au besoin, avec suivi facturation`,
                `Rapports et bilans`,
              ],
            },
          ],
        },
        administration: {
          title: `Administration`,
          id: `amalyet-operations-ventes-administration`,
          introduction: `Nous offrons trois modules dans l'opérationnalisation de l’administration. Il est possible d’ajouter d’autres modules au besoin, comme la formation, etc.`,
          modules: [
            {
              title: `Employés`,
              introduction: `Un module permettant de faire n’importe quel suivi administratif et opérationnel de vos employés.`,
            },
            {
              title: `Recrutement`,
              introduction: `Un ATS (Applicant Tracking System) simple qui vous permet d’opérer les éventuels recrutement que vous faites.`,
            },
            {
              title: `Projets`,
              introduction: `Ce module permet d’ajouter des projets au besoin avec les possibilités suivantes:`,
              items: [
                `Définir la manière la plus appropriée pour la planification de son contenu ainsi que son exécution (tâches, activités, templates, etc.)`,
                `Choix des assignations des ressources à mettre dans le projet`,
                `Suivi d’avancement et du budget`,
              ],
            },
          ],
        },
        reports: {
          title: `Rapports`,
          id: `amalyet-operations-ventes-reports`,
          introduction: `Un système de rapports dynamiques à base de technicités d’intelligence d’affaire, entre autre un système de rapport croisés dynamiques, avec les processus:`,
          items: [
            `Génération des rapports`,
            `Partage des rapports entre gestionnaires`,
            `Système de communication sur les rapports`,
          ],
        },
        suite: {
          title: `A-Suite`,
          id: `amalyet-operations-ventes-suites`,
          introduction: `Amalyet-Suite et une suite office permettant une intégration administrative au portail Amalyet et intégrant:`,
          items: [
            `Un gestionnaire de documents`,
            `Un gestionnaire de notifications`,
            `Un gestionnaire de courriels`,
            `Un calendrier`,
            `Un gestionnaire de communication`,
            `Le timesheet`,
            `La présence`,
          ],
        },
      },
    },
    jobshop: {
      id: `amalyet-jobshop`,
      title: 'Amalyet-JobShop',
      introduction: `Amalyet-JobShop is a special implementation of Amalyet-portal intended for organizations working in the field of manufacturing in general, and specifically custom manufacturing. The major challenges of these companies can be summed up, among other things, by:`,
      items: [
        `Cotes: The prices of client projects are obtained from a quotation which must represent its tailor-made achievement`,
        `Exact price of bids: Increase the number of bids at the slightest blow and having the most exact prices`,
        `Custom vs general: Carry out custom projects while making the same products`,
        `Planning: Build several projects at the same time which makes their planning difficult`,
        `Learning: The rush of multitudes of client projects means that organizational learning is neglected`,
        `Computerization: In general, Job-Shops are very small companies that do not have the means to acquire an integrated management system.`,
      ],
      conclusion: `This implementation of Amalyet-Portail aims to meet the efficiency needs of Job-Shop type organizations, at very affordable prices.`,
      configuration: {
        title: 'Organization configuration',
        id: 'amalyet-jobshop-organisation',
        introduction:
          'In the core of Amalyet-JobShop, organizational knowledge is described by its three core processes as follows:',
        sections: [
          {
            title: 'Fabrication',
            id: 'amalyet-jobshop-organisation-fabrication',
            introduction:
              'The configuration of knowledge to ensure efficient manufacturing, among others:',
            items: [
              `Workshop layout: It contains the production stations as well as the operations that are carried out in each station`,
              `Production resources: Operators, machines and materials`,
              `Pre-assignment of resources to operations with their cost structures`,
              `Subcontractors who can carry out production operations`,
              `Configuration of manufactured products, either by configurator, BOM, BOO, template, etc.`,
              `Quality: The various quality operations, picking, control points, types of alerts, etc.`,
              `Maintenance: Types of maintenance on machinery.`,
            ],
          },
          {
            title: 'Administration',
            id: 'amalyet-jobshop-organisation-administration',
            introduction:
              'An administration configuration capable of supporting the operations of the organization',
            items: [
              `Resource cost structure`,
              `The organization's budget`,
              `The employees`,
              `Types of employment`,
              `Administration activities`,
              `Suppliers.`,
            ],
          },
          {
            title: 'Sales',
            id: 'amalyet-jobshop-organisation-ventes',
            introduction:
              'A configuration of sales procedures of the organization ',
            items: [
              `The structure of the cote and sales email templates`,
              `Taxes`,
              `Sales activities`,
              `Customers`,
              `Sellers`,
              `Reasons for lost of sales opportunities.`,
            ],
          },
        ],
        operations: {
          title: `Operations`,
          id: `amalyet-jobshop-operations`,
          introduction: `We implement in this service the operational modules, which are also grouped by the master processes Sales, Fabrication and Administration.`,
          sales: {
            title: 'Sales',
            id: 'amalyet-jobshop-operations-sales',
            introduction: `Operationalization-Sales implements the operational processes relating to sales with the objectives:`,
            items: [
              `Increase the ability to bid`,
              `Have a more precise cost of cotes, and closer to the cost of production`,
            ],
            conclusion: `It is a mini CRM with 3 process modules:`,
            modules: [
              {
                title: `Opportunities`,
                introduction: `We implement the sub-processes:`,
                id: `amalyet-jobshop-operations-sales-opportunities`,
                items: [
                  `Sales targets: Planning and monitoring of sales targets for sellers`,
                  `Leads: To add potential customers to contact`,
                  `Opportunities: Detailed follow-up of salespeoples activities with potential customers, with the objective of transforming an opportunity into a quote.`,
                ],
              },
              {
                title: `Cotes`,
                introduction: `It is possible to pass from an opportunity to a cote, or to create a cote directly. The cote includes 4 sub-processes.`,
                id: `amalyet-jobshop-operations-sales-quotes`,
                items: [
                  `Versions: Possibility of establishing different budget scenarios`,
                  `Budget: Creation of the content of each item of the cote to calculate the cost in a more or less automatic way, using for each product manufactured its configuration`,
                  `Invitations: Association of subcontractors for the execution of part of the client project, invite them to bid, monitor their cotations and decide with whom to work`,
                  `Invoice: Email quotes to clients and follow up.`,
                ],
              },
              {
                title: `Clients`,
                introduction: `This module allows you to follow up on customer accounts`,
                id: `amalyet-jobshop-operations-sales-clients`,
                items: [
                  `Integrated communications`,
                  `Sales, quotes and opportunities dashboard.`,
                ],
              },
            ],
          },
          execution: {
            title: `Execution`,
            id: `amalyet-jobshop-operations-execution`,
            introduction: `The Operationalization-Execution department implements the essential processes for the realization of customer projects (manufacturing). It contains 3 process modules:`,
            modules: [
              {
                id: `amalyet-jobshop-operations-execution-fabrication`,
                title: `Manufacturing`,
                introduction: `This module allows from the budget of a cote to plan and monitor the execution of a client project. It has the sub-processes:`,
                items: [
                  `Budget management with the possibility of creating several project budgets (versions)`,
                  `Work planning: Plan in time the course of the activities to be performed in the project (Gantt)`,
                  `Monitoring of changes made by customers`,
                  `Manufacturing monitoring by operation`,
                  `Reports and reviews.`,
                ],
              },
              {
                id: `amalyet-jobshop-operations-execution-maintenance`,
                title: `Maintenance`,
                introduction: `This module allows the management and monitoring of planned preventive and emergency maintenance of workshop machines.`,
                items: [
                  `Action plan for all planned maintenance`,
                  `Emergency maintenance management: Ability to assign, record (video, files, procedures) of each emergency maintenance`,
                  `Condition management of each machine requiring maintenance.`,
                ],
              },
              {
                id: `amalyet-jobshop-operations-execution-qualité`,
                title: `Quality`,
                introduction: `This module makes it possible to plan and follow all the quality management procedures on the manufactured products. This module implements the following sub-processes:`,
                items: [
                  `Quality objectives`,
                  `Picking planning and validation procedures`,
                  `Operations targeted by quality and control points`,
                  `Alerts after control`,
                  `Quality reports.`,
                ],
              },
            ],
          },
          administration: {
            id: `amalyet-jobshop-operations-administration`,
            title: `Administration`,
            introduction: `We offer three modules in operationalizing administration. It is possible to add other modules as needed, such as training, etc.`,
            modules: [
              {
                title: `Employees`,
                id: 'amalyet-jobshop-operations-administration-employees',
                introduction:
                  'A module allowing you to do any administrative and operational monitoring of your employees.',
              },
              {
                title: `Recruitment`,
                id: 'amalyet-jobshop-operations-administration-recrutement',
                introduction:
                  'A simple ATS (Applicant Tracking System) that allows you to carry out any recruitment you make.',
              },
              {
                title: `Projects`,
                id: 'amalyet-jobshop-operations-administration-projet',
                introduction:
                  'This module allows you to add projects as needed with the following possibilities:',
                items: [
                  `Define the most appropriate way for planning its content as well as its execution (tasks, activities, templates, etc.)`,
                  `Choice of resource assignments to put in the project`,
                  `Progress and budget monitoring`,
                ],
              },
            ],
          },
          reports: {
            id: `amalyet-jobshop-operations-reports`,
            title: `Reports`,
            introduction: `A dynamic reporting system based on business intelligence technicalities, among other things a dynamic cross-reporting system, with the processes:`,
            items: [
              `Generating reports`,
              `Sharing of reports between managers`,
              `Reporting communication system.`,
            ],
          },
          suite: {
            id: `amalyet-jobshop-operations-suite`,
            title: `Amalyet-Suite`,
            introduction:
              'Amalyet-Suite and an office suite allowing administrative integration into the Amalyet portal and integrating:',
            items: [
              `A document manager`,
              `A notification manager`,
              `An email manager`,
              `A calendar`,
              `A communication manager`,
              `The timesheet`,
              `The presence.`,
            ],
          },
        },
      },
    },
  },
}
