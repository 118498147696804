import React from 'react'
import { useTranslation } from 'react-i18next'

export default function About() {
  const { t } = useTranslation()

  return (
    <section className="about-us-area section_gap_top" id="about-us">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <div className="main_title">
              <p className="top_title"> {t('about:title')} </p>
              <h2> {t('about:about')} </h2>
              <div className="text-justify">
                {' '}
                {t('about:description').map((content) => (
                  <p>{content}</p>
                ))}
              </div>
              <a href="/#contact" className="custom_btn  mt-4">
                {t('projectFinance:learnMore')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
