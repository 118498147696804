import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import Swal from 'sweetalert2'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const initial = {
  nameForm: 'contactForm',
  name: '',
  email: '',
  message: '',
  isProcess: false,
  isInfosUs: false,
  isStartProcess: false,
}

export default function Contact() {
  const { t } = useTranslation()
  const [form, setForm] = useState(initial)
  const [loading, setLoading] = useState(false)
  const setField = useCallback(
    (event) => {
      setForm({ ...form, [event.target.name]: event.target.value })
    },
    [form],
  )

  const setCheckField = useCallback(
    (event) => {
      setForm({ ...form, [event.target.name]: event.target.checked })
    },
    [form],
  )

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault()
      setLoading(true)
      axios
        .get(process.env.REACT_APP_MESSAGES, { params: form })
        .then(() => {
          Swal.fire({
            title: t('contact:successMsg'),
            icon: 'success',
            showCloseButton: true,
            showConfirmButton: false,
            timer: 8000,
          })
          setForm(initial)
        })
        .catch((err) => {
          Swal.fire({
            title: t('contact:failureMsg'),
            icon: 'error',
            showCloseButton: true,
          })
        })
        .finally(() => setLoading(false))
    },
    [form, t],
  )
  return (
    <section className="contact_area section_gap" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-7">
            <h2>{t('contact:title')}</h2>

            <p>{t('contact:description.introduction')}</p>
            <ul>
              {t('contact:description.items').map((item) => (
                <li>{item}</li>
              ))}
            </ul>
            <p>{t('contact:description.conclusion')}</p>
          </div>
          <div className="col-12 col-md-1"> </div>
          <div className="col-12 col-md-4">
            <form
              className="row contact_form "
              id="contactForm"
              onSubmit={onSubmit}
              name="contactForm"
            >
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="name_feedback_area">
                    {t('contact:name')}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name_feedback_area"
                    name="name"
                    value={form.name}
                    onChange={setField}
                    placeholder={t('contact:p_name')}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email_feedback_area">
                    {t('contact:mail')}
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email_feedback_area"
                    name="email"
                    value={form.email}
                    onChange={setField}
                    placeholder={t('contact:p_mail')}
                    required
                  />
                </div>

                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck1"
                    name="isStartProcess"
                    onChange={setCheckField}
                    checked={form.isStartProcess}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck1"
                  >
                    {t('contact:begin_process')}
                  </label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck2"
                    name="isProcess"
                    onChange={setCheckField}
                    checked={form.isProcess}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck2"
                  >
                    {t('contact:isProcess')}
                  </label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck3"
                    name="isInfosUs"
                    onChange={setCheckField}
                    checked={form.isInfosUs}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck3"
                  >
                    {t('contact:infoUs')}
                  </label>
                </div>
                <div className="custom-control custom-checkbox mb-2">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck4"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck4"
                  >
                    {t('contact:other')}
                  </label>
                </div>

                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    id="message_feedback_area"
                    rows="1"
                    placeholder={t('contact:p_message')}
                    required
                    value={form.message}
                    onChange={setField}
                  ></textarea>
                </div>
                <div className="col-md-12 text-right">
                  <button type="submit" className="custom_btn">
                    {loading ? (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 30, color: 'white' }}
                          />
                        }
                      />
                    ) : (
                      t('contact:submit')
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
