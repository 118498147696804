import React from 'react'
import ProjectFinanceItem from './ProjectFinanceItem'
import { useTranslation } from 'react-i18next'

export default function ProjectFinance() {
  const { t } = useTranslation()

  return (
    <section id="finance-your-project" className="section_gap big_features">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <div className="main_title">
              <p className="top_title">{t('projectFinance:title')}</p>
              <h2>{t('projectFinance:subTitle')}</h2>
              <p> {t('projectFinance:description')} </p>
            </div>
          </div>
        </div>
        {t('projectFinance:items.audit').map((item, index) => (
          <ProjectFinanceItem key={index} {...item} image="img/f-img1.png" />
        ))}

        {t('projectFinance:items.pme').map((item, index) => (
          <ProjectFinanceItem
            key={index}
            {...item}
            image="img/f-img2.png"
            reverse={true}
          />
        ))}
      </div>
    </section>
  )
}
