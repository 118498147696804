export default {
  en: {
    title: 'We are your partner to lead you to your real digitization',
    copyright: 'All rights reserved',
    Newsletter: 'Newsletter',
    upTrend: 'Stay updated with our latest trends',
    mail: 'Email Adress',
    p_mail: 'Enter your email address',
    follow: 'Follow Me!',
    social: 'Let us be social',
  },
  fr: {
    title:
      'Nous sommes votre partenaire pour vous mener à votre réelle numérisation',
    copyright: 'Tous droits réservés',
    Newsletter: 'Newsletter',
    upTrend: 'Restez à jour avec nos dernières tendances',
    mail: 'Adress Mail',
    p_mail: 'Saisissez votre adresse mail',
    follow: 'Suivez-moi!',
    social: 'soyons sociaux',
  },
}
