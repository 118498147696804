import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LocalStorageBackend from 'i18next-localstorage-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import header from 'components/Header/lang'
import home from 'components/HomeBanner/lang'
import about from 'components/About/lang'
import approach from 'components/Approach/lang'
import projectFinance from 'components/ProjectFinance/lang'
import contact from 'components/Contact/lang'
import clients from 'components/Clients/lang'
import consultans from 'components/Consultans/lang'
import domaines from 'components/Domaines/lang'
import program from 'components/Program/lang'
import footer from 'components/Footer/lang'
import formation from 'pages/formation/lang'
import consultation from 'pages/consultation/lang'
import amalyet from 'pages/amalyet/lang'

export default i18n
  .use(initReactI18next)
  .use(LocalStorageBackend)
  .use(LanguageDetector)
  .init({
    returnObjects: true,
    resources: {
      en: {
        header: header.en,
        home: home.en,
        about: about.en,
        approach: approach.en,
        projectFinance: projectFinance.en,
        contact: contact.en,
        clients: clients.en,
        consultans: consultans.en,
        domaines: domaines.en,
        program: program.en,
        footer: footer.en,
        formation: formation.en,
        consultation: consultation.en,
        amalyet: amalyet.en,
      },
      fr: {
        header: header.fr,
        home: home.fr,
        about: about.fr,
        approach: approach.fr,
        projectFinance: projectFinance.fr,
        contact: contact.fr,
        clients: clients.fr,
        consultans: consultans.fr,
        domaines: domaines.fr,
        program: program.fr,
        footer: footer.fr,
        formation: formation.fr,
        consultation: consultation.fr,
        amalyet: amalyet.fr,
      },
    },
    whitelist: ['en', 'fr'],
    nonExplicitWhitelist: false,
    nonExplicitSupportedLngs: true,
    fallbackLng: 'en',
    load: 'languageOnly',
    interpolation: { escapeValue: false },
    react: {
      useSuspense: false,
    },
  })
