import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export function Approach() {
  const { t } = useTranslation()

  return (
    <section className="features_area_1">
      <div className="container text-center">
        <h2> {t('approach:title')}</h2>
        <div className="row">
          {/* <div className="col-12 col-md-4 mb-3 d-flex flex-column align-items-center">
            <i className="fal fa-users-class fa-5x"></i>
            <h4 className="mt-4">{t('approach:approach_1')}</h4>
            <p className="w-75 mx-auto text_feature	">
              {t('approach:description_1')}
            </p>
            <Link to="/formation" className="know_more_btn">
              {t('approach:learn_more')}
            </Link>
          </div> */}
          <div className="col-12 col-md-6 text mb-3 d-flex flex-column align-items-center">
            <i className="fal fa-user-headset fa-5x"></i>
            <h4 className="mt-4">{t('approach:approach_2')}</h4>
            <p className=" w-75 mx-auto text_feature">
              {t('approach:description_2')}
            </p>
            <Link to="/consultation" className="know_more_btn">
              {t('approach:learn_more')}
            </Link>
          </div>
          <div className="col-12 col-md-6 mb-3 d-flex flex-column align-items-center">
            <i className="fal fa-desktop-alt fa-5x"></i>
            <h4 className="mt-4">{t('approach:approach_3')}</h4>
            <p className="w-75 mx-auto text_feature">
              {t('approach:description_3')}
            </p>
            <Link to="/amalyet" className="know_more_btn">
              {t('approach:learn_more')}
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
