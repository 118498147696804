export default {
  en: {
    title: 'Formation in Action ',
    subtitle: 'Formation',
    main_title:
      'Training on operational efficiency in a context of digitization',
    main_content_items: [
      {
        title: 'Complete trainings',
        icon:
          'https://res.cloudinary.com/springboard-images/image/upload/c_limit,h_60,q_auto,dpr_1.0,fl_lossy/v1591602086/homepage-assets/career-tracks/iconos-2-01_3x.png',
        description:
          'Our training courses cover the ins and outs of digitization',
      },
      {
        title: 'Certified team',
        icon:
          'https://res.cloudinary.com/springboard-images/image/upload/c_limit,h_60,q_auto,dpr_1.0,fl_lossy/v1591602085/homepage-assets/career-tracks/3-job-guarantee-05_3x.png',
        description:
          'Our trainers are experienced and certified in digitization',
      },
      {
        title: 'Practical experiences',
        icon:
          'https://res.cloudinary.com/springboard-images/image/upload/c_limit,h_60,q_auto,dpr_1.0,fl_lossy/v1591602085/homepage-assets/career-tracks/iconos-2-03_3x.png',
        description:
          'Our trainings and trainers are oriented towards the practice of digitization',
      },
    ],
    our_formations: 'Our Formations',
    our_formations_subtitle: `It is our expertise and knowledge
          that enabled us to build Amalyet, which we have organized in the form of courses accessible
           to you`,
    error_message: 'Can not load data',
    more_details: 'More Details',
    our_approach: 'Our Approach',
    explore_formations: 'Explore our Formations',
    our_instructors: 'Our Instructors',
    approach: [
      {
        title: 'Control your project',
        description:
          'One of the values of our integrated digitization approach is that you take control of your digitization project.',
      },
      {
        title: 'Evolve your organization',
        description:
          'This is important because your organization is evolving, and digitization is going to have to evolve with it.',
      },
      {
        title: 'Facilitate your project',
        description:
          'We have worked to make your digitization project as a management and administration project to give you that control.',
      },
      {
        title: 'Accessible training',
        description:
          'We have organized a set of very accessible training courses for managers in order to introduce you to the theoretical and practical tools for managing operational efficiency.',
      },
    ],
  },
  fr: {
    title: 'Formation in Action ',
    subtitle: 'Formation',
    main_title:
      'Formation sur l’efficacité opérationnelle dans un contexte de numérisation',
    main_content_items: [
      {
        title: 'Formations complètes',
        icon:
          'https://res.cloudinary.com/springboard-images/image/upload/c_limit,h_60,q_auto,dpr_1.0,fl_lossy/v1591602086/homepage-assets/career-tracks/iconos-2-01_3x.png',
        description:
          'Nos formations couvrent les tenants et aboutissants de la numérisation',
      },
      {
        title: 'Équipe certifiées',
        icon:
          'https://res.cloudinary.com/springboard-images/image/upload/c_limit,h_60,q_auto,dpr_1.0,fl_lossy/v1591602085/homepage-assets/career-tracks/3-job-guarantee-05_3x.png',
        description:
          'Nos formateurs sont expérimentés et certifiés en numérisation',
      },
      {
        title: 'Expériences pratiques',
        icon:
          'https://res.cloudinary.com/springboard-images/image/upload/c_limit,h_60,q_auto,dpr_1.0,fl_lossy/v1591602085/homepage-assets/career-tracks/iconos-2-03_3x.png',
        description:
          'Nos formations et formateurs sont orientés vers la pratique de la numérisation',
      },
    ],
    our_formations: 'Nos Formations',
    our_formations_subtitle: `C’est notre expertise et connaissances
          qui nous ont permis de bâtir Amalyet que nous avons organisées en
          forme de cours accessibles à vous`,
    error_message: 'Impossible de charger les données',
    more_details: 'Plus de détails',
    our_approach: 'Notre Approche',
    explore_formations: 'Explorez nos formations',
    our_instructors: 'Nos Instructeurs',
    approach: [
      {
        title: 'Contrôler de votre projet',
        description:
          'Une des valeurs de notre approche de numérisation intégrée est que vous preniez le contrôle de votre projet de numérisation.',
      },
      {
        title: 'évoluer votre organisation',
        description:
          'C’est important, car votre organisation évolue, et la numérisation va devoir évoluer avec.',
      },
      {
        title: 'Faciliter de votre projet',
        description:
          'Nous avons travaillé pour rendre votre  projet de numérisation comme un projet de gestion et d’administration pour vous assurer ce contrôle.',
      },
      {
        title: 'Formations accessibles',
        description:
          'Nous avons organisé un ensemble de formations très accessibles, pour gestionnaires, afin de vous introduire les outils théoriques et pratiques à la gestion de l’efficacité opérationnelle.',
      },
    ],
  },
}
